<template>
	<v-card
		class="mx-auto"
		style="
			width: 100%;
			max-width: 100%;
			min-height: 344px;
			margin-left: 10px !important;
			background-color: #272429;
		"
	>
		<v-img class="white--text align-end" height="341.78px" :src="path">
			<v-row>
				<v-col cols="5"></v-col>
				<v-col cols="7">
					<v-img
						:src="img"
						style="height: 58px !important; width: 58px !important"
					></v-img>
					<v-card-title style="padding-bottom: 5px">
						<v-row style="margin-bottom: 20px !important">
							<v-col cols="12" style="padding-bottom: 0; padding-top: 25px">
								<span v-html="titulo1"></span>
							</v-col>
							<v-col cols="12" style="padding-bottom: 0; padding-top: 35px">
								<span v-html="subtitulo"></span>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-actions>
						<v-btn v-if="boton != ''" @click="btnConprarAhora_click" class="btninfo">{{
							boton
						}}</v-btn>
					</v-card-actions>
				</v-col>
			</v-row>
		</v-img>
	</v-card>
</template>
<style>
.btninfo {
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	color: #4b0b7f !important;
	background-color: #fac903 !important;
	border-color: #02010100 !important;
	padding: 15px 30px !important;
	height: 52px !important;
}
.btninfo:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
</style>
<script>
export default {
	name: "CardsCarouselPrincipal",
	props: {
		path: {
			type: [Number, String],
			default: "",
		},
		img: {
			type: [Number, String],
			default: "",
		},
		titulo1: {
			type: [Number, String],
			default: "",
		},
		subtitulo: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
	},
	methods: {
		btnConprarAhora_click() {
			let routeData = this.$router.resolve({
				path: this.link,
			});
			if (this.interna == 1) {
				window.open(routeData.href, this.target);
			} else {
				window.open(this.link, this.target);
			}
		},
	},
};
</script>