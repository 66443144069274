<template>
  <v-row style="text-align: center" >
    <v-col cols="12" lg="12" sm="10" md="9">
      <v-card
        class="mx-auto"
        color="purple darken-4"
        height="490"
        flat
      >
      <v-row style="text-align: center">
        <v-col cols="12" lg="1" sm="12" md="9"></v-col>

        <v-col cols="12" lg="10" sm="12" md="9" class="pt-10">
          <v-card id="rounded-card" class="purple darken-3 text-center pa-0">
            <v-row >
              <v-col cols="12" lg="7" sm="12" md="9">
                <v-row class="pa-15">
                  <v-col>
                    <v-row>
                      <h2
                        style="
                          border-radius: 8px;
                          font-family: sans-serif;
                          color: white;
                          height: 50px;
                          font-size: 25px;
                          text-align: center;
                          width: 80px;
                          padding: 6px;
                          background-color: rgba(255, 0, 0, 0.8);
                        "
                      >
                        Lives
                      </h2>
                    </v-row>
                    <v-row>
                      <h2
                        style="
                          font-family: sans-serif;
                          color: white;
                          font-size: 30px;
                          font-weight: bold;
                          text-align: left;
                          margin-top: 10px;
                        "
                      >
                        {{banners.BannerLive[0].title}}
                      </h2>
                    </v-row>
                    <v-row>
                      <h2
                        style="
                          font-family: sans-serif;
                          color: white;
                          font-weight: bold;
                          font-size: 30px;
                          text-align: left;
                          line-height: 30px;
                        "
                      >
                        {{banners.BannerLive[0].subtitle}}
                      </h2>
                    </v-row>
                    <v-row class="mt-8">
                      <h2
                        style="
                          font-family: sans-serif;
                          color: #bdbdbd;
                          text-align: justify;
                          font-size: 15px;
                          line-height: 15px;
                        "
                      >
                        {{banners.BannerLive[0].des}}
                      </h2>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    style="
                      content-align: justify;
                      text-transform: capitalize;
                      margin-top: -20px;
                      margin-left: 60px;
                      font-family: 'Raleway', sans-serif;
                      font-size: 15px;
                    "
                    id="boton2"
                    ><b> Nuestros Programas </b></v-btn
                  >
                </v-row>
              </v-col>

              <v-col cols="12" lg="5" sm="12" md="9">
                <v-img
                  v-if="
                    $vuetify.breakpoint.name != 'xs' &&
                    $vuetify.breakpoint.name != 'sm'
                  "
                  :src="images.sample"
                  style="margin-top: 30px; height: 310px; margin-left: -40px"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" lg="1" sm="12" md="9"></v-col>
      </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
export default {
  data() {
    return {
      banners: bannersData,
      images: {
        sample: require("../../assets/play.png"),
      },
    };
  },
};
</script>
<style>
#redonda {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 3px #525252;
  overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
#redonda:hover {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 3px rgb(236, 118, 6);
  overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
#book {
  margin-top: 20px;
  margin-left: 0px;
  background-color: transparent;
  box-shadow: none;
  color: #525252;
  margin-top: 10px;
  margin-left: -10px;
  height: 80px;
}
#book:hover {
  box-shadow: none;
  color: rgb(236, 118, 6);
}
#boton2 {
  background-color: #ffd600;
  box-shadow: none;
  color: #6a1b9a;
}
#boton2:hover {
  box-shadow: none;
  color: white;
  background-color: #6a1b9a;
}
.positionlogo {
  margin-top: 0px;
  margin-left: -26px;
}
#rounded-card {
  border-radius: 10px;
}
</style>
