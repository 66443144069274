<template>
	<v-container style="padding-top: 0; padding-bottom: 0; min-height: 350px">
		<v-row style="padding-left: 25px; padding-right: 50px">
			<v-col
				cols="12"
				style="
					background-color: transparent;
					background-image: linear-gradient(150deg, #64289f 10%, #3e026e 56%);
					padding-bottom: 0;
					border-radius: 15px;
					padding-top: 0;
				"
			>
				<v-row>
					<v-col cols="8" style="padding-top: 80px; padding-left: 70px">
						<h2
							style="
								color: #ffffff;
								font-family: 'Raleway', Sans-serif;
								font-size: 32px;
								font-weight: 800;
								line-height: 1.1em;
								letter-spacing: -0.7px;
								margin-bottom: 20px;
							"
						>
							{{ niufleexPremium.titulo }}
						</h2>
						<h2
							style="
								color: #ddb2ff;
								font-family: 'Raleway', Sans-serif;
								font-size: 18px;
								padding-bottom: 40px;
								font-weight: 500;
								line-height: 1em;
								letter-spacing: 0px;
							"
						>
							{{ niufleexPremium.subtitulo }}
						</h2>
						<v-btn
							v-if="niufleexPremium.boton"
							class="btniraniutfleex"
							@click="btnPremium_click"
							>{{ niufleexPremium.boton }}</v-btn
						>
					</v-col>
					<v-col cols="4">
						<v-img :src="niufleexPremium.src"></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<style lang="scss" scoped>
.btniraniutfleex {
	font-family: "Raleway", Sans-serif !important;
	font-size: 16px !important;
	font-weight: 800 !important;
	height: 53px !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	box-shadow: 0px 0px 24px 1px rgb(1 1 1 / 26%) !important;
	padding: 20px 40px 17px 40px !important;
}
.btniraniutfleex:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
</style>
<script>
export default {
	data() {
		return {
			niufleexPremium: {
				src: "http://niufleex.com/wp-content/uploads/2021/11/sergio-p.png",
				titulo:
					"¡No dejes pasar la oportunidad y aprende de los que HACEN y saben hacer!",
				subtitulo: "Nuestra membresía PREMIUM te permitirá acceder a diversos contenidos que te enseñarán de liderazgo, negocios, prospección, entre otras herramientas",
				boton: "Consigue tu membresía Premium",
				link: "https://backoffice.teomacorp.com/login?ReturnUrl=%2f",
				target: "_blank",
				interna: 0,
			},
			niufleexPremiumExample: {
				src: "http://niufleex.com/wp-content/uploads/2021/11/sergio-p.png",
				titulo:
					"¡No dejes pasar la oportunidad y aprende de los que HACEN y saben hacer!",
				subtitulo:
					"Bajada de texto al titular que empuje la compra. Bajada de texto al titular que empuje la compra.",
				boton: "¡IR A NIUFLEEX PREMIUM!",
				link: "https://niufleex.com",
				target: "_blank",
				interna: 0,
			},
		};
	},
	methods: {
		btnPremium_click() {
			let routeData = this.$router.resolve({
				path: this.niufleexPremium.link,
			});
			if (this.niufleexPremium.interna == 1) {
				window.open(routeData.href, this.niufleexPremium.target);
			} else {
				window.open(this.niufleexPremium.link, this.niufleexPremium.target);
			}
		},
	},
};
</script>