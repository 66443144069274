<template>
	<v-card
		elevation="10"
		class="mx-auto"
		max-width="400"
		style="
			border-radius: 5px;
			width: 100%;
			max-width: 100%;
			min-height: 400px;
			margin-left: 10px !important;
		" light
	>
		<v-img v-if="path" class="white--text align-end" height="160px" :src="path" light>
			<!-- <v-card-title style="padding-left: 0">
				<v-chip
					class="ma-2"
					label
					style="
						margin-left: 7px !important;
						margin-top: -75px !important;
						color: #541983;
						background-color: #fac903;
						border-radius: 5px 5px 5px 5px;
						font-family: 'Raleway', Sans-serif;
						font-size: 13px;
						font-weight: 600;
						height: 25px;
						letter-spacing: 0px;
						padding: 7px 10px 3px 10px;
					"
				>
					Más vistos
				</v-chip>
			</v-card-title> -->
			<v-card-title style="padding-bottom: 5px">
				<v-row>
					<v-col cols="9" style="padding-bottom: 0">
						<v-icon v-if="estudiantes" large left dark class="cardicon v-icon-left2" >
							mdi-account-multiple
						</v-icon>
						<span v-if="estudiantes" class="cardicon">{{ estudiantes }} estudiantes</span>
					</v-col>
					<v-col
						cols="3"
						style="padding-bottom: 0; padding: 0; padding-top: 24px"
					>
						<v-icon large left dark class="cardicon v-icon-left2">
							mdi-diamond
						</v-icon>
						<span class="cardicon">{{ puntuacion }}</span>
					</v-col>
				</v-row>
			</v-card-title>
		</v-img>

		<v-card-subtitle class="pb-0"
			><h2 class="h2Cards" style="color: #fb950c">{{ nombre }}</h2>
			<!-- <h2 class="h2Cards">{{ resenia }}</h2> -->
		</v-card-subtitle>

		<v-card-text class="text--primary">
			<div style="margin: 0px 0px 0px 0px; padding: 20px 15px 0px 0px">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					Por: {{ especialista }}
				</h2>
			</div>

			<v-row style="margin-bottom: 20px !important">
				<v-col cols="7" style="padding-bottom: 0; padding-top: 15px">
					<v-icon large left light class="cardicon v-icon-left2">
						mdi-format-list-bulleted
					</v-icon>
					<span
						style="
							font-family: 'Poppins', Sans-serif;
							font-size: 14px;
							font-weight: 400;
							letter-spacing: 0.2px;
							color:#000;
						"
						>{{ sesiones }} Sesiones</span
					>
				</v-col>
				<v-col
					cols="5"
					style="padding-bottom: 0; padding: 0; padding-top: 15px"
				>
					<v-icon large left light class="cardicon v-icon-left2">
						mdi-clock
					</v-icon>
					<span
						style="
							font-family: 'Poppins', Sans-serif;
							font-size: 14px;
							font-weight: 400;
							letter-spacing: 0.2px; color:#000;
						"
						>{{ minutos }} min.</span
					>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions style="bottom: 0; position: absolute; width: 100%">
			<v-btn @click="btnIrCurso_click" class="btnircurso">{{ boton }}</v-btn>
		</v-card-actions>
	</v-card>
</template>
<style>
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
.btnircurso {
	font-family: "Raleway", Sans-serif !important;
	font-size: 17px !important;
	font-weight: 600 !important;
	width: 100% !important;
	letter-spacing: 0px !important;
	height: 60px !important;
	fill: #8208b1 !important;
	color: #8208b1 !important;
	background-color: #ffffff !important;
	border-style: solid !important;
	border-width: 3px 3px 3px 3px !important;
	border-color: #8208b1 !important;
	border-radius: 3px 3px 3px 3px !important;
	padding: 20px 15px 17px 15px !important;
}
.btnircurso:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
</style>
<script>
export default {
	props: {
		path: {
			type: [Number, String],
			default: "",
		},
		estudiantes: {
			type: [Number, String],
			default: "",
		},
		puntuacion: {
			type: [Number, String],
			default: "",
		},
		nombre: {
			type: [Number, String],
			default: "",
		},
		resenia: {
			type: [Number, String],
			default: "",
		},
		especialista: {
			type: [Number, String],
			default: "",
		},
		sesiones: {
			type: [Number, String],
			default: "",
		},
		minutos: {
			type: [Number, String],
			default: "",
		},
		titulo: {
			type: [Number, String],
			default: "",
		},
		subtitulo: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
	},
	mounted() {
		// eslint-disable-next-line no-console
		console.log(this.link);
	},
	methods: {
		btnIrCurso_click() {
			// let routeData = this.$router.resolve({
			// 	path: this.link,
			// });
			// if (this.interna == 1) {
			// 	window.open(routeData.href, this.target);
			// } else {
			// 	window.open(this.link, this.target);
			// }
			this.$router.push(this.link);

			// eslint-disable-next-line no-console
			console.log(this.target, this.link, this.interna);
		},
	},
};
</script>