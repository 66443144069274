<template>
	<v-container
		fluid
		style="
			background-color: #f3f3f3;
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
		"
		dense
		v-if="$vuetify.breakpoint.name == 'xs'"
	>
		<section
			v-if="!premium"
			style="
				margin-top: 25px;
				background-color: rgb(39, 36, 41);
				padding-top: 0;
				padding-bottom: 0;
			"
		>
			<ComprarahoraXS></ComprarahoraXS>
		</section>
		<section class="fondo-banner">
			<CarouselPrincipalXS></CarouselPrincipalXS>
		</section>
		<section style="background-color: #6a289f; padding: 20px">
			<CarouselContinuarReproduciendoXS></CarouselContinuarReproduciendoXS>
		</section>
		<section style="margin-bottom: 20px; margin-top: 25px; min-height: 550px">
			<CarouselFreeXS></CarouselFreeXS>
		</section>
		<section style="margin-bottom: 20px; padding-top: 0">
			<NiufleexPremiumXS></NiufleexPremiumXS>
		</section>
		<!-- <section style="margin-bottom: 40px; margin-top: 40px">
      <NiufleexLiveXS></NiufleexLiveXS>
    </section> -->
		<!-- <section style="background-color: white">
    <SoporteNiufleex></SoporteNiufleex>
  </section>
  <section style="background-color: #4b0b7f">
    <FooterUno></FooterUno>
  </section>
  <section style="background-color: #541983; height: 90px; padding-top: 30px">
    <FooterDos></FooterDos>
  </section> -->
	</v-container>
	<v-container
		fluid
		style="
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
		"
		v-else-if="
			$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
		"
	>
		<section
			v-if="!premium"
			style="
				margin-top: 25px;
				background-color: rgb(39, 36, 41);
				padding-top: 0;
				padding-bottom: 0;
			"
		>
			<ComprarahoraXS></ComprarahoraXS>
		</section>
		<section class="fondo-banner">
			<CarouselPrincipalXS></CarouselPrincipalXS>
		</section>
		<section style="background-color: #6a289f; padding-bottom: 20px">
			<CarouselContinuarReproduciendoSM></CarouselContinuarReproduciendoSM>
		</section>
		<section style="margin-bottom: 20px; margin-top: 25px; min-height: 550px">
			<CarouselFreeSM></CarouselFreeSM>
		</section>
		<section style="margin-bottom: 20px; padding-top: 0">
			<NiufleexPremiumXS></NiufleexPremiumXS>
		</section>
		<!-- <section style="margin-bottom: 40px; margin-top: 40px">
      <NiufleexLiveXS></NiufleexLiveXS>
    </section> -->
		<!-- <section style="background-color: white">
    <SoporteNiufleex></SoporteNiufleex>
  </section>
  <section style="background-color: #4b0b7f">
    <FooterUno></FooterUno>
  </section>
  <section style="background-color: #541983; height: 90px; padding-top: 30px">
    <FooterDos></FooterDos>
  </section> -->
		<section style="margin-bottom: 20px">
			<FiveStar></FiveStar>
		</section>
	</v-container>
	<v-container
		v-else
		fluid
		style="
			padding: 0px;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
		"
	>
		<section
			v-if="!premium"
			style="
				margin-top: 36px;
				background-color: rgb(39, 36, 41);
				padding-top: 0;
				padding-bottom: 0;
			"
		>
			<Comprarahora></Comprarahora>
		</section>
		<section class="fondo-banner">
			<CarouselPrincipalMD></CarouselPrincipalMD>
		</section>
		<section style="background-color: #3e026e; padding-bottom: 20px">
			<CarouselContinuarReproduciendoMD></CarouselContinuarReproduciendoMD>
		</section>
		<section
			style="
				margin-bottom: 0px;
				padding-top: 0;
				margin-top: 20px;
				margin-bottom: 30px;
			"
		>
			<CarouselFree nombremetodo="cardsCursosFree"></CarouselFree>
		</section>
		<section style="margin-bottom: 20px">
			<NiufleexPremium></NiufleexPremium>
		</section>
		<!-- <section style="margin-bottom: 40px; margin-top: 40px">
      <NiufleexLive></NiufleexLive>
    </section> -->
		<!-- <section style="background-color: white">
    <SoporteNiufleex></SoporteNiufleex>
  </section>
  <section style="background-color: #4b0b7f">
    <FooterUno></FooterUno>
  </section>
  <section style="background-color: #541983; height: 90px; padding-top: 30px">
    <FooterDos></FooterDos>
  </section> -->
		<section style="margin-bottom: 20px">
			<FiveStar></FiveStar>
		</section>
	</v-container>
</template>

<style>
.level-30[data-v-0ca40a08] {
	display: none !important;
}

.oculta {
	display: none !important;
}

.fondo-banner {
	background-color: transparent;
	background-image: linear-gradient(90deg, #3e026e 11%, #710c68 100%);
}
</style>

<script>
//CALIFICACION FIVE STAR
import FiveStar from "../components/shared/FiveStar.vue";

import CarouselPrincipalMD from "../components/explora_teams/CarruselPrincipal/CarouselPrincipalMD.vue";
import CarouselPrincipalXS from "../components/explora_teams/CarruselPrincipal/CarouselPrincipalXS.vue";

import CarouselContinuarReproduciendoMD from "../components/explora_teams/CarruselContinuarReproduciendo/CarruselContinuarReproduciendo.vue";
import CarouselContinuarReproduciendoSM from "../components/explora_teams/CarruselContinuarReproduciendo/CarruselContinuarReproduciendoSM.vue";
import CarouselContinuarReproduciendoXS from "../components/explora_teams/CarruselContinuarReproduciendo/CarruselContinuarReproduciendoXS.vue";

import CarouselFree from "../components/explora_teams/CarruselCursosFree/CarruselCardsFreeMD.vue";
import CarouselFreeSM from "../components/explora_teams/CarruselCursosFree/CarruselCardsFreeSM.vue";
import CarouselFreeXS from "../components/explora_teams/CarruselCursosFree/CarruselCardsFreeXS.vue";

import Comprarahora from "../components/shared/comprarahora.vue";
import ComprarahoraXS from "../components/shared/comprarAhoraXS.vue";

import NiufleexPremium from "../components/explora_teams/Banners/NiufleexPremium.vue";
import NiufleexPremiumXS from "../components/explora_teams/Banners/NiufleexPremiumXS.vue";

import NiufleexLive from "../components/explora_teams/Banners/NiufleexLive.vue";
import NiufleexLiveXS from "../components/explora_teams/Banners/NiufleexLiveXS.vue";

import FooterUno from "../components/explora_teams/Footer/FooterUno.vue";
import FooterDos from "../components/explora_teams/Footer/FooterDos.vue";
import SoporteNiufleex from "../components/explora_teams/Footer/SoporteNiufleex.vue";

import { mapState } from "vuex";
export default {
	data: () => ({}),
	components: {
		CarouselPrincipalMD,
		CarouselContinuarReproduciendoMD,
		CarouselFree,
		CarouselPrincipalXS,
		CarouselContinuarReproduciendoSM,
		CarouselContinuarReproduciendoXS,
		CarouselFreeSM,
		CarouselFreeXS,
		Comprarahora,
		ComprarahoraXS,
		NiufleexPremium,
		NiufleexPremiumXS,
		NiufleexLive,
		FooterUno,
		FooterDos,
		SoporteNiufleex,
		NiufleexLiveXS,
		FiveStar,
	},
	computed: {
		...mapState(["premium"]),
	},
};
</script>
