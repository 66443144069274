<template>
	<v-container style="padding-top: 0; padding-bottom: 0">
		<v-row style="padding-left: 25px; padding-right: 50px">
			<v-col
				cols="12"
				style="
					ackground-color: transparent;
					background-image: linear-gradient(90deg, #3e026e 11%, #710c68 100%);
					padding-bottom: 0;
					border-radius: 15px;
					padding-top: 0;
					word-break: break-word;
				"
			>
				<v-row style="margin: 0">
					<v-col cols="12" style="padding-bottom: 0">
						<img
							style="width: 100%; text-align: center"
							:src="niufleexLives.src"
						/>
					</v-col>
					<v-col cols="12">
						<v-chip
							label
							style="
								font-family: 'Raleway', Sans-serif;
								font-size: 25px;
								font-weight: 800;
								letter-spacing: 0px;
								width: 83.33px;
								heigth: 39px;
								fill: #ffffff;
								color: #ffffff;
								margin-bottom: 10px;
								background-color: #ff0000;
								border-radius: 3px 3px 3px 3px !important;
								padding: 7px 7px 7px 7px;
							"
						>
							LIVES
						</v-chip>
						<h2
							v-html="niufleexLives.titulo"
							style="
								color: #ffffff;
								font-family: 'Raleway', Sans-serif;
								font-size: 32px;
								font-weight: 800;
								line-height: 1.1em;
								letter-spacing: -0.7px;
								margin-bottom: 20px;
							"
						></h2>
						<h2
							style="
								color: #ddb2ff;
								font-family: 'Raleway', Sans-serif;
								font-size: 18px;
								padding-bottom: 40px;
								font-weight: 500;
								line-height: 1em;
								letter-spacing: 0px;
							"
						>
							{{ niufleexLives.subtitulo }}
						</h2>
						<v-btn
							v-if="niufleexLives.boton"
							class="btniraniutfleex"
							@click="btnPremium_click"
						>
							{{ niufleexLives.boton }}
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<style lang="scss" scoped>
.btniraniutfleex {
	font-family: "Raleway", Sans-serif !important;
	font-size: 16px !important;
	font-weight: 800 !important;
	height: 53px !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	box-shadow: 0px 0px 24px 1px rgb(1 1 1 / 26%) !important;
	padding: 20px 40px 17px 40px !important;
}
.btniraniutfleex:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
</style>
<script>
export default {
	data() {
		return {
			niufleexLives: {
				src: "http://niufleex.com/wp-content/uploads/2022/01/lives.png",
				titulo: "NIUFLEEX Comunidad. <br/> Aprende y Emprende",
				subtitulo:
					"Únete a nuestras transmisiones en RRSS: Niufleex Business, Aprende y Emprende, Ser Inquebrantable y más",
				boton: "",
				link: "https://niufleex.com",
				target: "_blank",
				interna: 0,
			},
		};
	},
	methods: {
		btnPremium_click() {
			let routeData = this.$router.resolve({
				path: this.niufleexLives.link,
			});
			if (this.niufleexLives.interna == 1) {
				window.open(routeData.href, this.niufleexLives.target);
			} else {
				window.open(this.niufleexLives.link, this.niufleexLives.target);
			}
		},
	},
};
</script>