<template>
  <v-container style="padding-top: 0; padding-bottom: 0">
    <v-row>
      <v-col cols="12" md="12" sm="12" lg="2" xl="2">
        <a href="https://niufleex.com/" target="_blank">
          <img
            style="height: 27.25px"
            src="https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none"
          />
        </a>
      </v-col>
      <v-col cols="12" md="12" sm="12" lg="2" xl="2">
        <a href="https://niufleex.com/" target="_blank">
          <img
            style="height: 27.25px"
            src="https://niufleex.com/wp-content/uploads/2021/12/TEOMA.png"
          />
        </a>
      </v-col>
      <v-col cols="12" md="12" sm="12" lg="5" xl="5">
        <p
          style="
            text-align: left;
            color: #b26fe7;
            font-family: 'Raleway', Sans-serif;
            font-size: 14px;
            font-weight: 500;
          "
        >
          Copyright © NIUFLEEX 2022 – Todos los derechos reservados
        </p>
      </v-col>
      <v-col cols="12" md="12" sm="12" lg="3" xl="3">
        <a text href="https://www.facebook.com/niufleex/">
          <i class="fab fa-facebook-f icono" style="font-size: 22px"></i>
        </a>
        <a text href="https://www.instagram.com/niufleex/">
          <i class="fab fa-instagram icono" style="font-size: 22px"></i>
        </a>
        <a text href="https://www.youtube.com/channel/UCz_IAx5oS2B9EWRw4nOVpEQ">
          <i class="fab fa-youtube icono" style="font-size: 22px"></i>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.icono {
  color: #b26fe7 !important;
  margin: 10px;
}
.icono:hover {
  color: #e8e8e8 !important;
}
</style>
<script>
export default {
  data() {
    return {
      footerUno: {
        tituloColumna1: "Compañía",
        tituloColumna2: "NIUFLEEX",
        tituloColumna3: "Comunidad",
        tituloColumna4: "Ayuda",
        compania: [
          {
            titulo: "NIUFLEEX",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "TEOMA",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "NIUFLEEX para EIT",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Membresía PREMIUM",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        niuleex: [
          {
            titulo: "Cursos",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Masterclass",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Especialistas",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        comunidad: [
          {
            titulo: "NIUFLEEX Bussiness",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Aprende y Emprende",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Ser Inquebrantable",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        ayuda: [
          {
            titulo: "Términos y Condiciones",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Políticas de Privacidad",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
      },
    };
  },
  methods: {
    btnConprarAhora_click() {
      let routeData = this.$router.resolve({
        path: this.modulosReproducidos.link,
      });
      if (this.modulosReproducidos.interna == 1) {
        window.open(routeData.href, this.modulosReproducidos.target);
      } else {
        window.open(
          this.modulosReproducidos.link,
          this.modulosReproducidos.target
        );
      }
    },
  },
};
</script>