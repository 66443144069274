<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<p
					style="
						text-align: center;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
				>
					Soporte NIUFLEEX
				</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" lg="4" xl="4">
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 800;
						letter-spacing: 0.2px;
					"
				>
					CANALES DE ATENCIÓN NIUFLEEX
				</p>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
					"
				>
					Si tienes preguntas, dudas o bien quieres hacernos una sugerencias, te
					puedes contactar con nosotros a:
				</p>
				<p>
					<v-btn
						style="
							font-family: 'Hind', Sans-serif;
							font-size: 14px;
							font-weight: 600;
							background-color: #54595f;
							border-radius: 5px 5px 5px 5px;
							padding: 15px 30px 10px 30px;
							height: 39px;
							color: white;
						"
						>niufleex.atencion@niufleex.com</v-btn
					>
				</p>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
					"
				>
					¡Mantengámonos siempre comunicados!
				</p>
			</v-col>
			<v-col cols="12" md="12" sm="12" lg="4" xl="4" light>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 800;
						letter-spacing: 0.2px;
						color:#000;
					"
				>
					SOPORTE WHATSAPP
				</p>
				<p style="text-align: left">
					Acompañamiento en el uso de tu Niufleex Free y Premium<br />–<br />Soporte
					para dudas de contenido<br />–<br />Soporte para EITs
				</p>
				<p>¡Mantengámonos siempre comunicados!</p>
				<p
					style="
						text-align: left;
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
					"
				>
					<strong
						><span style="color: #6a289f"
							><a
								style="color: #6a289f; text-decoration: none"
								href="https://niufleex.com/soporte"
								>* Ver todo</a
							></span
						></strong
					>
				</p>
			</v-col>
			<v-col
				cols="12"
				md="12"
				sm="12"
				lg="4"
				xl="4"
				style="margin-bottom: 10px"
			>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 800;
						letter-spacing: 0.2px;
					"
				>
					HORARIO GENERAL DE SERVICIO DE ATENCIÓN
				</p>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
					"
				>
					· Lunes a viernes: 09:00 am a 5:00 pm hora Perú
				</p>
				<a
					href="https://api.whatsapp.com/send?phone=56945764156&amp;text=Hola!:%20Quiero%20hacer%20una%20pregunta."
					target="_blank"
					class="whatsapp"
					role="button"
				>
					<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text"
							>Ejec. de atención [ES]: 09:00 am a 05:00 pm</span
						>
					</span>
				</a>
				<p
					style="
						text-align: left;
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 15px;
						font-weight: 500;
					"
				>
					· Día Sábado de 09:00 am a 2:00 pm hora Perú
				</p>
				<a
					href="https://api.whatsapp.com/send?phone=56945764156&amp;text=Hola!:%20Quiero%20hacer%20una%20pregunta."
					target="_blank"
					class="whatsapp"
					role="button"
				>
					<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text"
							>Ejec. de atención [ES]: 09:00 am a 05:00 pm</span
						>
					</span>
				</a>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.whatsapp {
	font-family: "Raleway", Sans-serif !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	letter-spacing: 0px !important;
	fill: #ffffff !important;
	color: #ffffff !important;
	background-color: #61ce70 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 8px 15px 6px 15px !important;
	text-decoration: none !important;
}
.whatsapp:hover {
	color: #ffffff !important;
	background-color: #515151 !important;
}
</style>
<script>
export default {
	data() {
		return {};
	},
};
</script>