<template>
	<v-row>
		<v-container>
			<h1
				style="margin-top: 0px; font-size: 30px"
				:style="{
					color: theme.title,
				}"
			>
				Módulos y Sesiones del Curso
			</h1>
		</v-container>
		<v-col cols="12" lg="12" sm="12" md="12">
			<v-card class="pa-6" elevation="0" light v-if="datosSesiones">
				<v-row class="mt-0" justify="center">
					<v-col cols="12" lg="7" sm="8" md="8" style="text-align: center">
						<v-list-item
							v-for="(item, i) in [...datosSesiones].slice(0, valor)"
							:key="i"
							style="text-align: left !important"
						>
							<v-list-item-content>
								<v-row>
									<v-col
										style="
											font-family: Raleway, 'Open Sans', sans-serif;
											color: #525252;
											font-size: 16px;
											margin: 9px 9px 0 9px;
											font-weight: bold;
											word-break: break-word;
										"
									>
										Módulo {{ item.orden }}:
										<label>{{ item.nombremodulo }}</label>
									</v-col>
								</v-row>
								<v-row>
									<v-col
										cols="12"
										lg="12"
										sm="12"
										md="9"
										v-for="(item2, index) of item.sesiones"
										:key="`m${item.nombremodulo}_${i}_sesiones_${index}_${item2.orden}`"
									>
										<router-link
											style="text-decoration: none"
											class="item-list-modulos"
											:to="`/play/${trackCode}#${item2.video_id}`"
										>
											<v-icon color="orange"> mdi-play </v-icon>
											<label
												style="
													color: orange;
													font-weight: bold;
													word-break: break-word;
												"
											>
												Sesión {{ item2.orden }}:
											</label>
											<label style="word-break: break-word">
												{{ item2.nombresesion }}
											</label>
										</router-link>
										<v-list-item-subtitle
											class="ml-2"
											v-text="item2.duracion"
										></v-list-item-subtitle>
									</v-col>
								</v-row>
								<v-divider></v-divider>
							</v-list-item-content>
						</v-list-item>

						<v-row>
							<v-col cols="12" lg="4" sm="12" md="4">
								<v-btn
									class="ma-2"
									outlined
									width="210"
									height="70"
									style="font-weight: bold; text-transform: capitalize"
									id="btnpopfleex2"
									@click="vertodo()"
									v-if="this.datosSesionesPopfleex.lenght != this.valor"
								>
									ver todos los módulos
								</v-btn>
								<v-btn
									class="ma-4"
									outlined
									width="210"
									height="70"
									style="font-weight: bold"
									id="btnpopfleex2"
									@click="vermenos()"
									v-if="this.datosSesionesPopfleex.lenght == this.valor"
								>
									ver menos
								</v-btn>
								<div class="mt-2"></div>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" lg="4" sm="4" md="4">
						<v-row>
							<v-col cols="12" lg="3" sm="12" md="9">
								<img
									:src="datosSesionesPopfleex[0].src"
									width="70"
									height="70"
								/>
							</v-col>
							<v-col cols="12" lg="6" sm="12" md="9">
								<h1
									style="
										font-family: 'Poppins', sans-serif;
										color: #525252;
										font-size: 20px;
										margin: 0px;
									"
								>
									Popfleex. Descárgalos aquí
								</h1>
							</v-col>
						</v-row>
						<v-row class="ml-0">
							<v-col cols="12" lg="10" sm="12" md="9">
								<p
									style="
										font-family: 'Raleway', sans-serif;
										color: #525252;
										font-size: 17px;
										margin: 0px;
										margin-top: 5px;
									"
								>
									{{ datosSesionesPopfleex[0].subtitle }}
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-btn
								class="ma-0"
								outlined
								width="200"
								height="60"
								style="font-weight: bold; text-transform: capitalize"
								id="btnpopfleex"
								v-if="(popfleex = false)"
								@click="descarga()"
							>
								Descarga Popfleex
							</v-btn>
							<v-btn
								class="ma-4"
								outlined
								width="200"
								height="60"
								style="
									font-weight: bold;
									text-transform: capitalize;
									font-size: 15px;
									font-family: 'Raleway', sans-serif;
								"
								id="btnpopfleex"
								v-if="(popfleex = true)"
							>
								No incluye Popfleex
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
import { mapState } from "vuex";
export default {
	props: {
		datosSesiones: {
			type: Array,
			default: [],
		},
		trackCode: {
			type: String,
			default: "",
		},
	},
	components: {},
	data() {
		return {
			popfleex: true,
			banners: bannersData,
			valor: 2,
			datosSesionesPopfleex: bannersData.datosSesionesPopfleex,
		};
	},
	methods: {
		vertodo() {
			this.valor = this.datosSesionesPopfleex.lenght;
			// eslint-disable-next-line no-console
			console.log(this.valor);
		},
		vermenos() {
			this.valor = 2;
			// eslint-disable-next-line no-console
			console.log(this.valor);
		},
	},
	mounted() {},
	computed: {
		...mapState(["theme"]),
	},
};
</script>
<style scoped>
#btnpopfleex {
	box-shadow: none;
	color: #6a1b9a;
	border: solid #6a1b9a 3px;
}
#btnpopfleex:hover {
	color: white;
	background-color: #6a1b9a;
}
#btnpopfleex2 {
	border: solid #6a1b9a 3px;
	color: #6a1b9a;
}
#btnpopfleex2:hover {
	color: white;
	box-sizing: border-box;
	background-color: #6a1b9a;
}
.item-list-modulos:hover * {
	color: orange;
}
</style>
