<template>
  <v-row style="text-align: center">
    <v-col cols="12" lg="12" sm="12" md="9">
      <v-card style="margin-top: -4px; margin-bottom: 0px;" class="mx-auto"  color="purple darken-4">
        <p
          style="
            font-family: 'Poppins', sans-serif;
            color: #ffffff;
            font-size: 37px;
            line-height: 55px;
          "
        >
          Próximas transmisiones
        </p>
        <v-row style="text-align: center">
          <v-col cols="12" lg="1" sm="12" md="9"></v-col>
          <v-col cols="12" lg="10" sm="12" md="9">
            <v-card
              elevation="12"
              width="1200"
              color="purple darken-3"
              class="mt-8 pa-6"
              style="border-radius: 10px"
            >
              <v-row>
                <v-col cols="12" lg="2" sm="12" md="9">
                  <h2
                    style="
                      font-family: sans-serif;
                      color: white;
                      font-size: 25px;
                      text-align: center;
                      background-color: red;
                    "
                  >
                    {{banners.ProximasTrasmisiones[0].mes}} 
                  </h2>
                  <h2
                    style="
                      border-bottom-left-radius: 10px;
                      border-bottom-right-radius: 10px;
                      font-family: 'Poppins', sans-serif;
                      color: #3c3c3c;
                      font-size: 45px;
                      height: 70px;
                      text-align: center;
                      background-color: white;
                    "
                  >
                    {{banners.ProximasTrasmisiones[0].dia}} 
                  </h2>
                </v-col>
                <v-col cols="12" lg="4" sm="12" md="9">
                  <img width="300" :src="images.sample" />
                </v-col>
                <v-col cols="12" lg="6" sm="12" md="9">
                  <div style="text-align: left">
                    <label
                      style="
                        font-family: 'Raleway', sans-serif;
                        background-color: #363535;
                        color: #e6d6b5;
                        font-size: 12px;
                        padding: 8px;
                        border-radius: 10px;
                      "
                    >
                      {{banners.ProximasTrasmisiones[0].tipo}} 
                    </label>
                  </div>
                  <p
                    style="
                      font-family: 'Poppins', sans-serif;
                      color: #ffffff;
                      font-size: 30px;
                      text-align: left;
                    "
                  >
                    {{banners.ProximasTrasmisiones[0].class}} 
                  </p>
                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 17px;
                      text-align: left;
                    "
                  >
                    {{banners.ProximasTrasmisiones[0].nombre}} 
                  </p>
                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 15px;
                      text-align: left;
                    "
                  >
                    Por {{banners.ProximasTrasmisiones[0].autor}} 
                  </p>

                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 15px;
                      text-align: left;
                    "
                  >
                   {{banners.ProximasTrasmisiones[0].sobre}} 
                  </p>
                  <div style="text-align: left">
                    <v-btn style="text-align: left; font-size: 10px" id="boton2"
                      ><b> inscripción </b></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="12"
              width="1200"
              color="purple darken-3"
              class="mt-1 pa-6"
              style="border-radius: 10px"
            >
              <v-row>
                <v-col cols="12" lg="2" sm="12" md="9">
                  <h2
                    style="
                      font-family: sans-serif;
                      color: white;
                      font-size: 25px;
                      text-align: center;
                      background-color: red;
                    "
                  >
                    {{banners.ProximasTrasmisiones2[0].mes}} 
                  </h2>
                  <h2
                    style="
                      border-bottom-left-radius: 10px;
                      border-bottom-right-radius: 10px;
                      font-family: 'Poppins', sans-serif;
                      color: #3c3c3c;
                      font-size: 45px;
                      height: 70px;
                      text-align: center;
                      background-color: white;
                    "
                  >
                    {{banners.ProximasTrasmisiones2[0].dia}} 
                  </h2>
                </v-col>
                <v-col cols="12" lg="4" sm="12" md="9">
                  <img width="300" :src="images.sample" />
                </v-col>
                <v-col cols="12" lg="6" sm="12" md="9">
                  <div style="text-align: left">
                    <label
                      style="
                        font-family: 'Raleway', sans-serif;
                        background-color: #363535;
                        color: #e6d6b5;
                        font-size: 12px;
                        padding: 8px;
                        border-radius: 10px;
                      "
                    >
                      {{banners.ProximasTrasmisiones2[0].tipo}} 
                    </label>
                  </div>
                  <p
                    style="
                      font-family: 'Poppins', sans-serif;
                      color: #ffffff;
                      font-size: 30px;
                      text-align: left;
                    "
                  >
                    {{banners.ProximasTrasmisiones2[0].class}}
                  </p>
                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 17px;
                      text-align: left;
                    "
                  >
                    {{banners.ProximasTrasmisiones2[0].nombre}} 
                  </p>
                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 15px;
                      text-align: left;
                    "
                  >
                    Por {{banners.ProximasTrasmisiones2[0].autor}} 
                  </p>

                  <p
                    style="
                      font-family: 'Raleway', sans-serif;
                      color: #ffffff;
                      font-size: 15px;
                      text-align: left;
                    "
                  >
                    {{banners.ProximasTrasmisiones2[0].sobre}} 
                  </p>
                  <div style="text-align: left">
                    <v-btn style="text-align: left; font-size: 10px" id="boton2"
                      ><b> inscripción </b></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" lg="1" sm="12" md="9"></v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
export default {
  data() {
    return {
      banners: bannersData,
      images: {
        sample: require("../../assets/cli.jpg"),
      },
    };
  },
};
</script>
<style scoped></style>
