<template>
      <v-toolbar class="grey darken-4 mb-2" height="80" >
        <v-row class="ml-10 mt-3">
          <v-col cols="12" lg="9" sm="9" md="9">
            <h3 class="mt-2" style="font-family: 'Poppins', sans-serif;
									color: #E6DEB5;">
              Accede a NIUFLEEX Membresía Premium por solo $50USD mensuales y
              logra tu rango.
            </h3>
          </v-col>
          <v-col cols="12" lg="2" sm="9" md="9">
            <v-btn outlined color="orange lighten-4" width="180" height="40" class="mt-1" style="font-family: 'Raleway', sans-serif; font-weight: bold;
									color: #E6DEB5; text-transform: capitalize;">
              !Compra Ahora¡ <v-icon> mdi-cursor-default-outline</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar>
   
</template>
<script>
export default {};
</script>
<style>
h3 {
  color: #ffe0b2;
}
</style>
