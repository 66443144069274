<template>
	<v-container style="padding-top: 40px; padding-bottom: 0">
		<v-row>
			<v-col
				v-if="carruselPrincipal.nombre"
				style="padding-bottom: 2px; text-align: center"
			>
				<h2
					style="
						color: #ffffff;
						font-family: 'Raleway', Sans-serif;
						font-size: 20px;
						font-weight: 400;
						line-height: 1.1em;
						letter-spacing: -0.7px;
					"
				>
					Hola <strong>{{ carruselPrincipal.nombre }}</strong>
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 0px; text-align: center">
				<h2
					style="
						color: #ffffff;
						font-family: 'Raleway', Sans-serif;
						font-size: 20px;
						font-weight: 400;
						line-height: 1.1em;
						letter-spacing: -0.7px;
					"
				>
					¡Bienvenido!
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 8px; padding-bottom: 0">
				<v-flex>
					<v-carousel
						hide-delimiters
						style="box-shadow: 0px 0px"
						class="carouselprincipal"
					>
						<v-carousel-item
							v-for="(i, indice) in carruselPrincipal.items.length"
							:key="i"
						>
							<v-layout row>
								<v-flex v-for="j in 1" :key="j" pl-2 pr-2>
									<CardsCarouselPrincipalXS
										:path="carruselPrincipal.items[indice].src_movil"
										:titulo1="carruselPrincipal.items[indice].titulo1"
										:img="carruselPrincipal.items[indice].img"
										:titulo2="carruselPrincipal.items[indice].titulo2"
										:subtitulo="carruselPrincipal.items[indice].subtitulo"
										:link="carruselPrincipal.items[indice].link"
										:target="carruselPrincipal.items[indice].target"
										:interna="carruselPrincipal.items[indice].interna"
										:boton="carruselPrincipal.items[indice].boton"
									></CardsCarouselPrincipalXS>
									<p style="display: none">
										{{ contador(carruselPrincipal.items.length) }}
									</p>
								</v-flex>
							</v-layout>
						</v-carousel-item>
					</v-carousel>
				</v-flex>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.carouselprincipal .v-carousel__controls {
	background-color: transparent !important;
}
.carouselprincipal .v-window__prev {
	margin-top: -7%;
	background: transparent !important;
}
.carouselprincipal .v-window__next {
	margin-top: -7%;
	background: transparent !important;
}
.carouselprincipal .v-window__prev .v-btn {
	width: 53px;
	height: 53px;
	background-color: transparent !important;
	color: #4b0b7f !important;
	font-size: 30px;
}
.carouselprincipal .v-window__next .v-btn {
	width: 53px;
	height: 53px;
	background-color: transparent !important;
	color: #4b0b7f !important;
	font-size: 30px;
}
</style>
<script>
import CardsCarouselPrincipalXS from "./CardsCarouselPrincipalXS.vue";
export default {
	data: () => ({
		indice: 0,
		carruselPrincipal: {
			nombre: "",
			items: [
				{
					src: "../../../images/base-top-lives.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/02/base-top-lives_movil-2.png",
					titulo1: `<h2 style="    color: white;
											font-family: Poppins, Sans-serif;
											font-size: 20px;
											font-weight: bold;
											margin-bottom: 0px;">NIUFLEEX Comunidad.</h2>
											<h2 style="color: white;
											font-family: Poppins, Sans-serif;
											font-size: 16px;
											font-weight: bold; word-break: break-word;
									"> Aprende y Emprende </h2>`,
					subtitulo: "",
					img: "../../../images/isologoD.png",
					boton: "",
					link: "/live",
					target: "_self",
					interna: 1,
					posicionimagen: "D",
					teams: [
						{
							name: "teomachile",
						},
						{
							name: "elite",
						},
					],
				},
				// {
				// 	src: "https://niufleex.com/wp-content/uploads/2022/02/top-banner-app-elite-banner.jpg",
				// 	src_movil:
				// 		"https://niufleex.com/wp-content/uploads/2022/02/top-banner-movil-elite-400.jpg",
				// 	titulo1: "",
				// 	subtitulo: "",
				// 	img: "",
				// 	boton: "",
				// 	link: "#",
				// 	target: "_self",
				// 	interna: 1,
				// 	posicionimagen: "I",
				// 	teams: [
				// 		{
				// 			name: "elite",
				// 		},
				// 	],
				// },
			],
		},
	}),
	components: {
		CardsCarouselPrincipalXS,
	},
	methods: {
		next() {
			this.onboarding =
				this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
		},
		prev() {
			this.onboarding =
				this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
		},

		contador(i) {
			if (this.indice < i) return parseInt(this.indice++);
			else return this.indice;
		},
	},
};
</script>