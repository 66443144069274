 
<template>
	<v-row justify="center">
		<v-snackbar
			:top="true"
			:color="alert.type"
			v-model="alert.snackbar"
			:timeout="4000"
		>
			<div v-html="alert.message"></div>
			<v-btn color="white" text @click="closeAlert"> X </v-btn>
		</v-snackbar>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-form @submit.prevent="enviarFormulario" ref="registeruser">
				<v-card>
					<v-card class="elevation-12 transparent">
						<v-card-text>
							<h2>Registro</h2>

							<v-text-field
								label="Nombre"
								placeholder="ej: Juan"
								filled
								color="#ffffff"
								v-model="form.firstname"
								:rules="rules.firstname"
								required
							></v-text-field>
							<v-text-field
								label="Email"
								v-model="form.email"
								filled
								type="text"
								:rules="rules.email"
							/>

							<v-row>
								<v-col cols="4">
									<v-combobox
										v-model="form.codigo"
										:items="codigos"
										label="Cód."
										filled
										color="#ffffff"
									></v-combobox>
								</v-col>
								<v-col>
									<v-text-field
										label="Fono"
										v-model="form.fono"
										color="#ffffff"
										filled
										type="text"
									/>
								</v-col>
							</v-row>
							<v-radio-group v-model="form.genero" row :rules="rules.genero">
								<v-radio label="Mujer" value="mujer"></v-radio>
								<v-radio label="Hombre" value="hombre"></v-radio>
								<v-radio
									label="No quiero especificar"
									value="sin-especificar"
								></v-radio>
							</v-radio-group>
						</v-card-text>
						<v-card-actions>
							<v-btn :loading="loading_enviar" class="niu-background-principal-2" block type="submit">
								Crear
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-card>
			</v-form>
		</v-dialog>
	</v-row>
</template>
<script>
// eslint-disable no-console
import nacionalidades from "../../services/nacionalidades";
import codigos from "../../services/codigos";
export default {
	created() {
		this.nacionalidad = nacionalidades;
		this.codigos = codigos;
		
		this.form.firstname = this.$auth?.user?.name;
		this.form.email = this.$auth?.user?.email;
	},
	data: () => ({
		alert: {
			type: "rojo",
			snackbar: null,
			message: null,
		},
		rules: {
			username: [
				(v) => !!v || "Usuario requerido",
				(v) => (v && v.length <= 30) || "Excede el límite de caracteres",
				(v) =>
					/^[a-z]{1,1}[a-z0-9]{3,29}$/.test(v) ||
					"Usuario no válido. Debe iniciar con una letra. Cantidad de caracteres del usuario entre 4 a 30, se aceptan solo letras en minúsculas y números.",
			],
			pass: [
				(v) => !!v || "Contraseña requerida",
				(v) => (v && v.length <= 20) || "Excede el límite de caracteres",
				(v) =>
					/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/.test(v) ||
					"La contraseña debe tener entre 8 y 20 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.",
			],
			folio: [(v) => !!v || "Folio requerido"],
			email: [
				(v) => !!v || "E-mail requerido",
				(v) => (v && v.length <= 100) || "Excede el límite de caracteres",
				(v) =>
					/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(v) ||
					"Email no válido",
			],

			firstname: [
				(v) => !!v || "Nombre requerido",
				(v) => (v && v.length <= 30) || "Excede el límite de caracteres",
			],
			firstsurname: [
				(v) => !!v || "Apellido requerido",
				(v) => (v && v.length <= 30) || "Excede el límite de caracteres",
			],
			nacionalidad: [
				(v) => !!v || "Nacionalidad requerida",
				(v) => (v && v.length <= 50) || "Excede el límite de caracteres",
			],
			buy: [(v) => !!v || "Número de compra requerido"],
			date: [(v) => !!v || "Fecha de compra requerida"],
			genero: [(v) => !!v || "Selección del sexo, requerida"],
			fono: [
				(v) => !!v || "Ingresar Número de Celular, requerida",
				(v) => (v && v.length <= 50) || "Excede el límite de caracteres",
			],
			code: [
				(v) => !!v || "Usuario requerido",
				(v) => (v && v.length <= 100) || "Excede el límite de caracteres",
				(v) => (v && v.length >= 10) || "Código no válido",
			],
		},
		form: {
			email: "",
			pass: "",
			firstname: "",
			firstsurname: "",
			pais: "",
			nrocompra: "",
			genero: "",
		},
		modal: false,
		loading_enviar: false,
		createRouteDialog: false,
		completar: false,
		codigos: ["+56", "+51"],
		flag: false,
	}),
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	updated() {
		if (!this.flag) {
			this.form.firstname = this.$auth.user.name;
			this.form.email = this.$auth.user.email;
			this.flag = true;
		}
	},
	methods: {
		closeAlert() {
			this.alert = {
				type: "rojo",
				message: "",
				snackbar: false,
			};
		},
		openAlert({ message, type = "rojo", snackbar = true }) {
			this.alert.message = message;
			this.alert.type = type;
			this.alert.snackbar = snackbar;
		},
		closeDialogFacebook() {
			this.$emit("closeDialogFacebook");
		},
		async enviarFormulario() {
			// eslint-disable-next-line no-console
			console.log("enviarFormulario");
			if (this.$refs["registeruser"].validate()) {
				this.completar = true;
				this.loading_enviar = true;
				try {
					// console.log(this.form)
					let form = {
						...this.form,
						username:
							this.form.username || this.$auth.user.sub.replace("|", ""),
						pass: this.$md5(this.$md5(this.utf8_to_b64(this.form.pass))),
						folio: "0",
						fono: `${this.form.codigo}${this.form.fono}`,
						nrocompra: this.form.nrocompra.toString(),
						fa_email: this.$auth.user.email,
						fa_family_name: this.$auth.user.family_name,
						fa_given_name: this.$auth.user.given_name,
						fa_name: this.$auth.user.name,
						fa_nickname: this.$auth.user.nickname,
						fa_picture: this.$auth.user.picture,
						fa_sub: this.$auth.user.sub,
						fa_updated_at: this.$auth.user.updated_at,
						fa_email_verified: this.$auth.user.email_verified,
					};
					// eslint-disable-next-line no-console
					console.log(form);

					await this.$http
						.post("/Registro/registrarFacebook", form)
						.then((response) => {
							// eslint-disable-next-line no-console
							console.log(response);

							if (
								response.data.mensaje == "ok" ||
								response.data.mensaje == "ErrorSession"
							) {
								this.loading_enviar = false;
								this.closeDialogFacebook();
								this.authenticate()
								this.form = {};
								if (response.data.mensaje != "ErrorSession") {
									this.urlLogin = `https://app.niufleex.com/login?access_token=${response.data.objecto.token}&user=${response.data.objecto.username}`;
								}
							}
						})
						//eslint-disable-next-line
						.catch((error) => {
							// eslint-disable-next-line no-console
							console.log(error);
							this.openAlert({
								message: `Error al enviar el formulario promise catch <br/> ${this.mostrarTiempo} ${error}`,
							});
						})
						.finally(() => {
							this.completar = false;
							this.loading_enviar = false;
						});
					//eslint-disable-next-line
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
					this.openAlert({
						message: `Error al enviar el formulario trycatch <br/> ${this.mostrarTiempo} ${error}`,
					});
				} finally {
					this.completar = false;
				}
			}
		},
		testData(fecha) {
			if (
				Date.parse(fecha) >= new Date(Date.parse("2020-04-01")) &&
				Date.parse(fecha) <= new Date()
			) {
				return true;
			}
			return false;
		},
		testDataFechaNac(fecha) {
			if (Date.parse(fecha) <= new Date()) {
				return true;
			}
			return false;
		},
		utf8_to_b64(str) {
			return window.btoa(unescape(encodeURIComponent(str)));
		},
		authenticate() {
			this.pasoAuth = true;
			// alert("paso auth0", this.$auth.user.sub);
			// eslint-disable-next-line no-console
			console.log("paso auth0", this.$auth.user);
			// eslint-disable-next-line no-console
			console.log(this.$auth.isAuthenticated);
			let query = "";
			this.showProgress = true;
			if (!this.checkbox1) {
				this.form.days = 30;
			}
			let nav = this.$getBrowserInfo();
			let ipLogin = "";
			this.$http
				.post("/users/authenticate", {
					username: this.$auth.user.sub.replace("|", ""),
					password: "-1",
					historial: {
						ipprivada: ipLogin || "",
						navegador: this.$storeNiu.getTextOrInt("navegador") || nav || "",
						vendor: window.navigator.vendor || "",
						styleMedia: "",
						appVersion: window.navigator.appVersion || "",
						platform: window.navigator.platform || "",
						userAgent: window.navigator.userAgent || "",
						// eslint-disable-next-line no-extra-boolean-cast
						subnav: !!navigator.brave
							? "brave"
							: // eslint-disable-next-line no-extra-boolean-cast
							!!window.chrome
							? "chrome"
							: "",
						baseURL: this.$http.defaults.baseURL,
					},
				})
				// this.login({ authMethod: this.authMode, credentials: this.form })
				.then((result) => {
					if (result) {
						window.resultlogin = result;
						this.$storeNiu.set("token", result.data.token);

						this.$storeNiu.set("data", result.data);

						this.$storeNiu.set("user", result.data.username);
						this.$storeNiu.set("team_guid", result.data.team_guid);
						this.$storeNiu.set("team", result.data.team_code);

						this.gtag("set", { user_id: result.data.username }); // Establezca el ID de usuario mediante el user_id con el que haya iniciado sesión.
						this.$store.commit("users", result.data);
					}
					this.$store.commit("alert", {
						type: "niu-background-principal-1",
						message: `Bienvenido ${result.data.firstname} ${result.data.firstsurname}`,
						snackbar: true,
					});
					// let redirect = null
					let validate_team = null;
					let verified_email = null;

					// if (this.$route.query['redirect']) {
					//   redirect = this.$route.query.redirect
					// }
					if (this.$route.query["validate_team"]) {
						validate_team = this.$route.query.validate_team;
					}
					if (this.$route.query["verified_email"]) {
						verified_email = this.$route.query.verified_email;
					}

					if (verified_email) {
						query = `verified_email=${verified_email}`;
					}
					if (validate_team) {
						query = query != "" ? "&" : "";
						query = `validate_team=${validate_team}`;
					}

					// eslint-disable-next-line no-console
					// console.log(this.$route)
					if (this.$route.query.orderid && this.$route.query.acceso) {
						this.$router.push(
							`/yo/${this.$storeNiu.getTextOrInt(
								"user"
							)}?renueva=membresia&orderid=${
								this.$route.query.orderid
							}&acceso=${this.$route.query.acceso}`
						);
					} else {
						if (this.$route.query.redirect) {
							this.$router.push(`${this.$route.query.redirect}`);
						} else {
							let home = result.data.team_code;
							// alert(home)
							if (result.data.membership_id == 2) {
								if (window.location.host == "app.niufleex.com") {
									home = process.env.VUE_APP_MAINPREMIUM;
									window.location.href = `https://premium.niufleex.com/${home}?access_token=${result.data.token}&user=${result.data.username}&${query}`;
								} else {
									if (query != "") {
										query = "?" + query;
									}
									home = process.env.VUE_APP_MAINPREMIUM;
									this.$router.push(`/${home}${query}`);
								}
								// this.$router.push({ name: 'explora_premium' })
							} else {
								if (result.data.membership_id == 1) {
									if (query != "") {
										query = "?" + query;
									}
									home = process.env.VUE_APP_MAINFREE;
									this.$router.push(`/${home}${query}`);
								} else {
									home = process.env.VUE_APP_MAINFREE;
									this.$router.push(`/${home}`);
								}
							}
						}
					}
				})
				//eslint-disable-next-line
				.catch((e) => {
					this.$emit("menufloat");
					this.$storeNiu.clear();
					if (e.response.data.code != "facebook") {
						this.$store.commit("alert", {
							type: "rojo",
							message:
								"Usuario o contraseña incorrecta.<br> ¡Inténtalo de nuevo por favor!", //+ e.response.data['message'],
							snackbar: true,
							moreinfo: true,
							timeout: 10000,
							adicional: false,
							msmAdicional: `${JSON.stringify(e)} <br/> ${
								this.$http.defaults.baseURL
							}`,
						});
						this.showProgress = false;
					} else {
						if (e.response.data.code == "facebook") {
							this.dialogFacebook = true;
						}
					}

					// eslint-disable-next-line no-console
					console.log(e.response);
				});
		},
	},
};
</script>
<style>
</style>