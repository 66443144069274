<template>
  <v-row style="text-align: center" class="pa-15">
    <v-col cols="12" lg="12" sm="12" md="9">
      <v-card elevation="0" class="pa-0">
        <p
          style="
            font-family: 'Poppins', sans-serif;
            color: #525252;
            font-weight: bold;
            font-size: 25px;
            line-height: 55px;
          "
        >
          Soporte NIUFLEEX
        </p>

        <v-row style="text-align: left">
          <v-col cols="12" lg="4" sm="12" md="9">
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-weight: bold;
                font-size: 15px;
                line-height: 55px;
              "
            >
              CANALES DE ATENCIÓN NIUFLEEX
            </p>

            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;

                font-size: 14px;
                text-align: left;
              "
            >
              Si tienes preguntas, dudas o bien quieres hacernos una
              sugerencias, te puedes contactar con nosotros a:
            </p>
            <p
              style="
                border-radius: 8px;
                font-family: sans-serif;
                color: white;
                height: 20px;
                font-size: 15px;
                text-align: center;
                width: 250px;
                padding: 0px;
                background-color: #54595f;
              "
            >
              niufleex.atencion@niufleex.com
            </p>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;

                font-size: 15px;
                text-align: left;
              "
            >
              ¡Mantengámonos siempre comunicados!
            </p>
          </v-col>
          <v-col cols="12" lg="3" sm="12" md="9">
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-weight: bold;
                font-size: 15px;
                line-height: 55px;
                color:#000;
              "
            >
              SOPORTE WHATSAPP
            </p>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-size: 14px;
                text-align: left;
                color:#000;
              "
            >
              Acompañamiento en el uso de tu Niufleex Free y Premium–
            </p>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-size: 14px;
                text-align: left;
                color:#000;
              "
            >
              Soporte para dudas de contenido–

            </p>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-size: 14px;
                text-align: left;
                color:#000;
              "
            >
              Soporte para EITs
            </p>
          </v-col>
          <v-col cols="12" lg="4" sm="12" md="9">
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-weight: bold;
                font-size: 15px;
                line-height: 55px;
              "
            >
              HORARIO GENERAL DE SERVICIO DE ATENCIÓN
            </p>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                font-size: 14px;
                text-align: left;
              "
            >
              · Lunes a viernes: 09:00 am a 5:00 pm hora Perú
            </p>
            <v-btn
              style="
                border-radius: 3px;
                font-family: sans-serif;
                color: white;
                height: 20px;
                font-size: 10px;
                text-align: center;
                width: 280px;
                padding: 0px;
                background-color: #61ce70;
              "
            >
              Ejec. de atención [ES]: 09:00 am a 05:00 pm
            </v-btn>
            <p
              style="
                font-family: 'Raleway', sans-serif;
                color: #525252;
                margin-top: 10px;
                font-size: 14px;
                text-align: left;
              "
            >
              · Día Sábado de 09:00 am a 2:00 pm hora Perú
            </p>
            <v-btn
              style="
                border-radius: 3px;
                font-family: sans-serif;
                color: white;
                height: 20px;
                font-size: 10px;
                text-align: center;
                width: 280px;
                padding: 0px;
                background-color: #61ce70;
              "
            >
              Ejec. de atención [ES]: 09:00 am a 02:00 pm
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style></style>
