<template>
  <v-container
    style="padding-top: 0; padding-bottom: 0 !important;"
  >
    <v-row>
      <v-col cols="12" md="6" sm="6" lg="3" xl="3" style="padding-bottom: 0;">
        <p class="ptitulo">{{ footerUno.tituloColumna1 }}</p>
        <ul style="padding-left:0;">
            <li v-for="item in footerUno.compania" :key="item.titulo" 
            style="list-style: none; margin-bottom:20px;">
                <a :href="item.link" :target="item.target" class="plista">
                    {{item.titulo}}
                </a>
            </li>
        </ul>
      </v-col>
      <v-col cols="12" md="6" sm="6" lg="3" xl="3" style="padding-bottom: 0;">
        <p class="ptitulo">{{ footerUno.tituloColumna2 }}</p>
        <ul style="padding-left:0;">
            <li v-for="item in footerUno.niuleex" :key="item.titulo" 
            style="list-style: none; margin-bottom:20px;">
                <a :href="item.link" :target="item.target" class="plista">
                    {{item.titulo}}
                </a>
            </li>
        </ul>        
      </v-col>
      <v-col cols="12" md="6" sm="6" lg="3" xl="3" style="padding-bottom: 0;">
        <p class="ptitulo">{{ footerUno.tituloColumna3 }}</p>
        <ul style="padding-left:0;">
            <li v-for="item in footerUno.comunidad" :key="item.titulo" 
            style="list-style: none; margin-bottom:20px;">
                <a :href="item.link" :target="item.target" class="plista">
                    {{item.titulo}}
                </a>
            </li>
        </ul>        
      </v-col>
      <v-col cols="12" md="6" sm="6" lg="3" xl="3" style="padding-bottom: 0;">
        <p class="ptitulo">{{ footerUno.tituloColumna4 }}</p>
        <ul style="padding-left:0;">
            <li v-for="item in footerUno.ayuda" :key="item.titulo" 
            style="list-style: none; margin-bottom:20px;">
                <a :href="item.link" :target="item.target" class="plista">
                    {{item.titulo}}
                </a>
            </li>
        </ul>        
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.ptitulo {
  text-align: left !important;
  color: #ffffff !important;
  font-family: "Poppins", Sans-serif !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
}
.plista {
  text-align: left !important;
  color: #ffffff !important;
  font-family: "Raleway", Sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
  margin-bottom: 20px !important;
  text-decoration: none !important;
}
</style>
<script>
export default {
  data() {
    return {
      footerUno: {
        tituloColumna1: "Compañía",
        tituloColumna2: "NIUFLEEX",
        tituloColumna3: "Comunidad",
        tituloColumna4: "Ayuda",
        compania: [
          {
            titulo: "NIUFLEEX",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "TEOMA",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "NIUFLEEX para EIT",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Membresía PREMIUM",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        niuleex: [
          {
            titulo: "Cursos",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Masterclass",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Especialistas",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        comunidad: [
          {
            titulo: "NIUFLEEX Bussiness",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Aprende y Emprende",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Ser Inquebrantable",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
        ayuda: [
          {
            titulo: "Términos y Condiciones",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
          {
            titulo: "Políticas de Privacidad",
            link: "https://niufleex.com",
            target: "_blank",
            interna: 0,
          },
        ],
      },
    };
  },
  methods: {
    btnConprarAhora_click() {
      let routeData = this.$router.resolve({
        path: this.modulosReproducidos.link,
      });
      if (this.modulosReproducidos.interna == 1) {
        window.open(routeData.href, this.modulosReproducidos.target);
      } else {
        window.open(
          this.modulosReproducidos.link,
          this.modulosReproducidos.target
        );
      }
    },
  },
};
</script>