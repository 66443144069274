<template>
	<v-container light>
		<v-row style="margin-bottom: 20px" light>
			<v-col cols="12" style="padding-bottom: 10px; text-align: center">
				<h2
					style="
						color: #3e026e;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.titulo }}
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 0; text-align: center">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ cardsCursosFree.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row
			v-if="
				cardsCursosFree &&
				cardsCursosFree.items &&
				cardsCursosFree.items.length > 0
			"
		>
			<v-flex>
				<v-carousel light
					hide-delimiters
					style="
						box-shadow: 0px 0px;
						padding-bottom: 50px;
						padding-left: 25px;
						padding-right: 15px;
						min-height: 550px !important;
						height: 550px !important;
					"
					class="csscards2XS"
				>
					<v-carousel-item light
						v-for="(i, indice) in cardsCursosFree.items.length"
						:key="i"
					>
						<v-layout row light>
							<v-flex v-for="j in 1" :key="j" pl-2 pr-2 light>
								<Cards2
									:path="cardsCursosFree.items[indice].src"
									:estudiantes="cardsCursosFree.items[indice].estudiantes"
									:puntuacion="cardsCursosFree.items[indice].puntuacion"
									:nombre="cardsCursosFree.items[indice].nombre"
									:resenia="cardsCursosFree.items[indice].resenia"
									:especialista="cardsCursosFree.items[indice].especialista"
									:sesiones="cardsCursosFree.items[indice].sesiones"
									:minutos="cardsCursosFree.items[indice].minutos"
									:link="cardsCursosFree.items[indice].link"
									:target="cardsCursosFree.items[indice].target"
									:interna="cardsCursosFree.items[indice].interna"
									:boton="cardsCursosFree.items[indice].boton"
								></Cards2>
								<p style="display: none">
									{{ contador(cardsCursosFree.items.length) }}
								</p>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
				<!-- <p
					style="
						margin-top: -135px;
						text-align: center;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 15px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.cantVistos }} de
					{{ cardsCursosFree.totCursos }} Cursos
				</p> -->
			</v-flex>
		</v-row>
	</v-container>
</template>
<style>
.csscards2XS .v-window__prev {
	margin-top: 220px  !important;
	margin-left: 25% !important;
}
.csscards2XS .v-window__next {
	margin-top: 220px  !important;
	margin-right: 25% !important;
}
.csscards2XS .v-window__prev .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: white !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.csscards2XS .v-window__next .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: white !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.csscards2XS .v-responsive__content .layout {
	padding-right: 35px !important;
}
</style>
<script>
import Cards2 from "./CardsFree.vue";
export default {
	data() {
		return {
			indice: 0,
			cardsCursosFree: [],
		};
	},
	components: {
		Cards2,
	},
	async mounted() {
		try {
			this.cardsCursosFree = (
				await this.$http.get(`/listacursos/${this.$route.params.team}`)
			).data[0];
			// eslint-disable-next-line no-console
			console.log(this.cardsCursosFree);
		} catch (error) {
			this.cardsCursosFree = [];
		}
	},
	methods: {
		contador(i) {
			if (this.indice < i) return parseInt(this.indice++);
			else return this.indice;
		},
		btnVerContenido_click() {
			let routeData = this.$router.resolve({
				path: this.cardsCursosFree.link_general,
			});
			if (this.interna_general == 1) {
				window.open(routeData.href, this.target_general);
			} else {
				window.open(this.cardsCursosFree.link_general, this.target_general);
			}
		},
	},
};
</script>