var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[(_vm.datosBannerEspecialista)?_c('v-card',{staticClass:"mt-3 pa-1",attrs:{"elevation":"0","color":"grey lighten-4"}},[_c('v-row',{staticClass:"pa-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"12","sm":"12","md":"9"}},[_c('p',{staticStyle:{"color":"#3e026e","margin-top":"10px","font-size":"30px","font-weight":"bold","font-family":"Poppins, 'Open Sans', sans-serif","margin-left":"50px"},style:({
									color: _vm.theme.title,
								})},[_vm._v(" Detalles del Curso y el Especialista ")])])],1),_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('v-col',{class:{
								fillHeight: ['md', 'xl', 'lg'].includes(
									_vm.$vuetify.breakpoint.name
								),
							},attrs:{"cols":"12","lg":"4","sm":"12","md":"4"}},[_c('v-card',{staticClass:"pa-6",class:{
									fillHeight: ['md', 'xl', 'lg'].includes(
										_vm.$vuetify.breakpoint.name
									),
								},staticStyle:{"text-align":"center"},attrs:{"light":"","elevation":"0"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('h1',{attrs:{"id":"texto"}},[_vm._v("Sobre el Especialista")]),_c('v-avatar',{attrs:{"size":"140px"}},[_c('img',{attrs:{"src":_vm.datosBannerEspecialista.src}})]),_c('h2',{staticStyle:{"color":"#525252"}},[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.nombre)+" ")]),_c('h2',{staticStyle:{"color":"#fb950c","font-size":"15px","font-family":"Poppins, 'Open Sans', sans-serif","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.subtitle)+" ")]),_c('p',{attrs:{"id":"textoespecialista"}},[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.cargo)+" ")]),_c('v-btn-toggle',{staticStyle:{"height":"40px"},attrs:{"multiple":""}},[(_vm.datosBannerEspecialista.facebook)?_c('a',{staticStyle:{"border":"none","padding":"5px","min-width":"0","text-decoration":"none"},attrs:{"href":'https://www.facebook.com/' +
												_vm.datosBannerEspecialista.facebook,"target":"_blank","elevation":"0"}},[_c('v-icon',{staticStyle:{"font-size":"30px"},attrs:{"color":"#FB950C"}},[_vm._v(" mdi-facebook ")])],1):_vm._e(),(_vm.datosBannerEspecialista.instagram)?_c('a',{staticStyle:{"border":"none","padding":"5px","min-width":"0","text-decoration":"none"},attrs:{"href":'https://www.instagram.com/' +
												_vm.datosBannerEspecialista.instagram,"target":"_blank","elevation":"0"}},[_c('v-icon',{staticStyle:{"font-size":"30px"},attrs:{"color":"#FB950C"}},[_vm._v(" mdi-instagram ")])],1):_vm._e(),(_vm.datosBannerEspecialista.youtube)?_c('a',{staticStyle:{"border":"none","padding":"5px","min-width":"0","text-decoration":"none"},attrs:{"href":_vm.datosBannerEspecialista.youtube,"target":"_blank","elevation":"0"}},[_c('v-icon',{staticStyle:{"font-size":"30px"},attrs:{"color":"#FB950C"}},[_vm._v(" mdi-youtube ")])],1):_vm._e(),(_vm.datosBannerEspecialista.tiktok)?_c('a',{staticStyle:{"border":"none","padding":"5px","min-width":"0","text-decoration":"none"},attrs:{"href":'https://www.tiktok.com/@' +
												_vm.datosBannerEspecialista.tiktok,"target":"_blank","elevation":"0"}},[_c('tiktok',{attrs:{"color1":'#FB950C',"color2":'#FB950C',"color3":'#FB950C'}})],1):_vm._e()])],1),_c('div',{staticClass:"divespecialista"},[_c('span',[_vm._v("Especialista")])])])],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12","lg":"7","sm":"12","md":"8"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"margin-top":"0px"},attrs:{"light":"","elevation":"0"}},[_c('h1',{staticStyle:{"color":"#908d9a","font-family":"'Poppins', Sans-serif","font-size":"25px","font-weight":"600","line-height":"1em","letter-spacing":"-0.7px","text-align":"center"},attrs:{"id":"texto"}},[_vm._v(" Sobre el curso ")]),_c('p',{staticClass:"textosub"},[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.sobre)+" ")]),_c('h1',{staticClass:"texto"},[_vm._v("¿Qué necesito?")]),_vm._l((_vm.datosBannerEspecialista.requisitos.split('-')),function(item,index){return _c('p',{key:index,staticClass:"textosub"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('h1',{staticClass:"texto"},[_vm._v("Aprenderás")]),_c('v-row',{staticClass:"pa-8",attrs:{"id":"textosub3"}},[_c('p',[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.aprenderas)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.aprenderas1)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.datosBannerEspecialista.aprenderas2)+" ")])])],2)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }