<template>
	<v-row justify="center">
		<v-col cols="12" md="10">
			<v-card
				class="mt-3 pa-1"
				elevation="0"
				color="grey lighten-4"
				v-if="datosBannerEspecialista"
			>
				<v-row justify="center" class="pa-6">
					<v-col cols="12">
						<v-row justify="center">
							<v-col cols="12" lg="12" sm="12" md="9">
								<p
									style="
										color: #3e026e;
										margin-top: 10px;
										font-size: 30px;
										font-weight: bold;
										font-family: Poppins, 'Open Sans', sans-serif;
										margin-left: 50px;
									"
									:style="{
										color: theme.title,
									}"
								>
									Detalles del Curso y el Especialista
								</p>
							</v-col>
						</v-row>
						<v-row justify="center" class="fill-height">
							<v-col
								cols="12"
								lg="4"
								sm="12"
								md="4"
								:class="{
									fillHeight: ['md', 'xl', 'lg'].includes(
										$vuetify.breakpoint.name
									),
								}"
							>
								<v-card
									light
									elevation="0"
									class="pa-6"
									style="text-align: center"
									:class="{
										fillHeight: ['md', 'xl', 'lg'].includes(
											$vuetify.breakpoint.name
										),
									}"
								>
									<div style="text-align: center">
										<h1 id="texto">Sobre el Especialista</h1>

										<v-avatar size="140px">
											<img :src="datosBannerEspecialista.src" />
										</v-avatar>

										<h2 style="color: #525252">
											{{ datosBannerEspecialista.nombre }}
										</h2>
										<h2
											style="
												color: #fb950c;
												font-size: 15px;
												font-family: Poppins, 'Open Sans', sans-serif;
												font-weight: normal;
											"
										>
											{{ datosBannerEspecialista.subtitle }}
										</h2>
										<p id="textoespecialista">
											{{ datosBannerEspecialista.cargo }}
										</p>

										<v-btn-toggle multiple style="height: 40px">
											<a
												v-if="datosBannerEspecialista.facebook"
												:href="
													'https://www.facebook.com/' +
													datosBannerEspecialista.facebook
												"
												target="_blank"
												elevation="0"
												style="
													border: none;
													padding: 5px;
													min-width: 0;
													text-decoration: none;
												"
											>
												<v-icon color="#FB950C" style="font-size: 30px">
													mdi-facebook
												</v-icon>
											</a>
											<a
												v-if="datosBannerEspecialista.instagram"
												:href="
													'https://www.instagram.com/' +
													datosBannerEspecialista.instagram
												"
												target="_blank"
												elevation="0"
												style="
													border: none;
													padding: 5px;
													min-width: 0;
													text-decoration: none;
												"
											>
												<v-icon color="#FB950C" style="font-size: 30px">
													mdi-instagram
												</v-icon>
											</a>
											<a
												v-if="datosBannerEspecialista.youtube"
												:href="datosBannerEspecialista.youtube"
												target="_blank"
												elevation="0"
												style="
													border: none;
													padding: 5px;
													min-width: 0;
													text-decoration: none;
												"
											>
												<v-icon color="#FB950C" style="font-size: 30px">
													mdi-youtube
												</v-icon>
											</a>
											<a
												v-if="datosBannerEspecialista.tiktok"
												:href="
													'https://www.tiktok.com/@' +
													datosBannerEspecialista.tiktok
												"
												target="_blank"
												elevation="0"
												style="
													border: none;
													padding: 5px;
													min-width: 0;
													text-decoration: none;
												"
											>
												<tiktok
													:color1="'#FB950C'"
													:color2="'#FB950C'"
													:color3="'#FB950C'"
												></tiktok>
											</a>
										</v-btn-toggle>
									</div>
									<!-- <div class="mt-3" style="text-align: center">
										<v-btn
											style="font-size: 12px"
											width="240px"
											height="30"
											id="botonespecialista"
											><b> conoce más del especialista </b></v-btn
										>
										<v-row class="mt-4"></v-row>
									</div> -->
									<div class="divespecialista"><span>Especialista</span></div>
								</v-card>
							</v-col>
							<v-col cols="12" lg="7" sm="12" md="8" class="fill-height">
								<v-card
									class="pa-4"
									style="margin-top: 0px"
									light
									elevation="0"
								>
									<h1
										id="texto"
										style="
											color: #908d9a;
											font-family: 'Poppins', Sans-serif;
											font-size: 25px;
											font-weight: 600;
											line-height: 1em;
											letter-spacing: -0.7px;
											text-align: center;
										"
									>
										Sobre el curso
									</h1>

									<p class="textosub">
										{{ datosBannerEspecialista.sobre }}
									</p>
									<h1 class="texto">¿Qué necesito?</h1>
									<p
										class="textosub"
										v-for="(
											item, index
										) of datosBannerEspecialista.requisitos.split('-')"
										:key="index"
									>
										{{ item }}
									</p>
									<h1 class="texto">Aprenderás</h1>
									<v-row id="textosub3" class="pa-8">
										<p>
											{{ datosBannerEspecialista.aprenderas }}
										</p>
										<p>
											{{ datosBannerEspecialista.aprenderas1 }}
										</p>
										<p>
											{{ datosBannerEspecialista.aprenderas2 }}
										</p>
									</v-row>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import tiktok from "../shared/iconssvg/tiktok.vue";
import bannersData from "../../services/datosvercursos.json";
import { mapState } from "vuex";
export default {
	components: {
		tiktok,
	},
	props: {
		datosBannerEspecialista: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapState(["theme"]),
	},
	data() {
		return {
			banners: bannersData,
		};
	},
};
</script>
<style scoped>
.btnespecialista,
.divespecialista {
	text-align: center;
	font-family: "Raleway", Sans-serif !important;
	font-size: 15px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #ffffff !important;
	color: #ffffff !important;
	background-color: #fb950c !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 12px 15px 9px 15px !important;
	width: 188px !important;
	font-family: "Raleway", Sans-serif;

	letter-spacing: 0px;
	display: inline-block;
	margin: 0 auto;
	height: 36px;
	align-items: center;
}

.bookFree {
	background-color: transparent;
	box-shadow: none;
	color: #525252;
	margin-top: 0px;
	height: 80px;
	width: 10%;
}
.bookFree:hover {
	box-shadow: none;
	color: rgb(236, 118, 6);
}
.redondaFree {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: solid 3px #525252;
	overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
.redondaFree:hover {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: solid 3px rgb(236, 118, 6);
	overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
.textosub {
	font-family: "Raleway", sans-serif;
	color: #525252;
	font-size: 17px;
	margin: 20px;
	margin-top: 10px;
	text-align: justify;
}
.textosub3 {
	font-family: "Raleway", sans-serif;
	color: #525252;
	font-size: 17px;
	text-align: justify;
	text-justify: left;
	line-height: 12px;
}
.texto {
	font-family: sans-serif;
	color: #908d9a;
	font-size: 25px;
	margin-top: 10px;
	margin: 5px;
	text-align: center;
}
.textoespecialista {
	font-family: Raleway, "Open Sans", sans-serif;
	color: #54595f;
	font-size: 17px;
	margin-top: 10px;
	margin: 10px;
	text-align: center;
}
.botonespecialista {
	background-color: orange;
	box-shadow: none;
	color: white;
}

.divespecialista span {
	text-align: center !important;
	margin: 0 auto !important;
}
</style>
