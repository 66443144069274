<template>
  <v-toolbar class="grey darken-4 mb-2" height="80">
    <v-col cols="12" lg="12" sm="12" md="9">
      <v-row class="mt-4">
        <h3
          class="mt-0 pa-0"
          style="
            font-size: 15px;
            content-align: justify;
            font-family: sans-serif;
          "
        >
          Accede a NIUFLEEX Membresía Premium por solo  $50USD mensuales y logra tu rango.
        </h3>
      </v-row>
      <v-row class="mt-2">
        <h3
          class="mt-0 pa-0"
          style="
            font-size: 14px;
            content-align: justify;
            font-family: sans-serif;
          "
        >
          <p
            style="
              color: #fb8c00;
              font-size: 15px;
              font-family: sans-serif;
              margin-top: 0px;
              font-weight: bold;
            "
          >
            !Compra Ahora¡
          </p>
        </h3>
      </v-row>
    </v-col>
  </v-toolbar>
</template>
<script>
export default {};
</script>
<style>
h3 {
  color: #ffe0b2;
}
</style>
