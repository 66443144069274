var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"id":"title-curso"}},[(
				(_vm.$vuetify.breakpoint.name == 'xs' ||
					_vm.$vuetify.breakpoint.name == 'sm') &&
				!_vm.premium
			)?_c('section',{staticClass:"flex-grow-0",staticStyle:{"background-color":"rgb(39, 36, 41)","padding-top":"0","padding-bottom":"0"}},[_c('ComprarAhoraXS')],1):_vm._e(),(
				_vm.$vuetify.breakpoint.name != 'xs' &&
				_vm.$vuetify.breakpoint.name != 'sm' &&
				!_vm.premium
			)?_c('section',{staticClass:"mt-3",staticStyle:{"background-color":"rgb(39, 36, 41)","padding-top":"0","padding-bottom":"0"}},[_c('ComprarAhoraMD')],1):_vm._e(),_c('section',{staticStyle:{"position":"static"}},[_c('BannerFree',{attrs:{"datosbannerprincipal":_vm.datosbannerprincipal,"img":_vm.img,"continuar":_vm.continuar}})],1),_c('section',{staticStyle:{"position":"static"}},[_c('Especialista',{attrs:{"datosBannerEspecialista":_vm.datosBannerEspecialista}})],1),_c('section',{staticStyle:{"position":"static"}},[_c('Sesiones',{attrs:{"trackCode":_vm.datosbannerprincipal.code,"datosSesiones":_vm.datosSesiones}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }