<template>
	<v-container>
		<v-row>
			<v-col cols="12" style="padding-bottom: 10px; text-align: center">
				<h2
					style="
						color: #3e026e;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.titulo }}
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 0; text-align: center">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ cardsCursosFree.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-carousel
					hide-delimiters
					style="
						box-shadow: 0px 0px;
						min-height: 550px !important;
						height: 550px !important;
					"
					class="csscards2SM"
				>
					<v-carousel-item
						v-for="i in Math.ceil(cardsCursosFree.items.length / 2)"
						:key="i"
					>
						<v-layout row>
							<v-flex sm6 pl-2 pr-2>
								<Cards2
									v-if="i * 2 - 2 < cardsCursosFree.items.length"
									:path="cardsCursosFree.items[i * 2 - 2].src"
									:estudiantes="cardsCursosFree.items[i * 2 - 2].estudiantes"
									:puntuacion="cardsCursosFree.items[i * 2 - 2].puntuacion"
									:nombre="cardsCursosFree.items[i * 2 - 2].nombre"
									:resenia="cardsCursosFree.items[i * 2 - 2].resenia"
									:especialista="cardsCursosFree.items[i * 2 - 2].especialista"
									:sesiones="cardsCursosFree.items[i * 2 - 2].sesiones"
									:minutos="cardsCursosFree.items[i * 2 - 2].minutos"
									:link="cardsCursosFree.items[i * 2 - 2].link"
									:target="cardsCursosFree.items[i * 2 - 2].target"
									:interna="cardsCursosFree.items[i * 2 - 2].interna"
									:boton="cardsCursosFree.items[i * 2 - 2].boton"
								></Cards2>
							</v-flex>
							<v-flex sm6 pl-2 pr-2>
								<Cards2
									v-if="i * 2 - 1 < cardsCursosFree.items.length"
									:path="cardsCursosFree.items[i * 2 - 1].src"
									:estudiantes="cardsCursosFree.items[i * 2 - 1].estudiantes"
									:puntuacion="cardsCursosFree.items[i * 2 - 1].puntuacion"
									:nombre="cardsCursosFree.items[i * 2 - 1].nombre"
									:resenia="cardsCursosFree.items[i * 2 - 1].resenia"
									:especialista="cardsCursosFree.items[i * 2 - 1].especialista"
									:sesiones="cardsCursosFree.items[i * 2 - 1].sesiones"
									:minutos="cardsCursosFree.items[i * 2 - 1].minutos"
									:link="cardsCursosFree.items[i * 2 - 1].link"
									:target="cardsCursosFree.items[i * 2 - 1].target"
									:interna="cardsCursosFree.items[i * 2 - 1].interna"
									:boton="cardsCursosFree.items[i * 2 - 1].boton"
								></Cards2>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
			</v-col>
			<!-- <v-col
				style="
					margin-top: -140px;
					margin-right: 35px !important;
					margin-left: 15px !important;
				"
				cols="12"
			>
				<p
					style="
						text-align: right;
						margin-right: 30%;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 24px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.cantVistos }} de
					{{ cardsCursosFree.totCursos }} Cursos
				</p>
			</v-col> -->
			<!-- <v-col
				cols="12"
				style="
					text-align: left;
					margin-top: -150px !important;
					margin-right: 35px !important;
					margin-left: 15px !important;
				"
			>
				<v-btn class="btntodocontenido" @click="btnVerContenido_click">{{
					this.cardsCursosFree.boton_general_xs
				}}</v-btn>
			</v-col> -->
		</v-row>
	</v-container>
</template>
<style>
.btntodocontenido {
	background-color: #fac903 !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	height: 55px !important;
}
.btntodocontenido:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.csscards2SM .v-window__prev {
	margin-top: 220px !important;
	margin-left: 75% !important;
}
.csscards2SM .v-window__next {
	margin-top: 220px !important;
	margin-right: 2% !important;
}
.csscards2SM .v-window__prev .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: white !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.csscards2SM .v-window__next .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: white !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.csscards2SM .v-responsive__content .layout {
	padding-right: 35px !important;
	padding-left: 12px !important;
}

.csscards2SM .v-carousel .v-window-item {
	height: 550px !important;
}
</style>
<script>
import Cards2 from "./CardsFree.vue";
export default {
	data() {
		return {
			indice: 0,
			cardsCursosFree: {
				titulo: "Cursos FREE disponibles para ti",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				link_general: "https://niufleex.com/",
				target_general: "_blank",
				interna_general: "0",
				boton_general: "ver todo el contenido",
				boton_general_xs: "ver todo",
				totCursos: 10,
				cantVistos: 3,
				items: [
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
					// 	estudiantes: "1.456",
					// 	puntuacion: "4.7",
					// 	nombre: "MÓDULO 1",
					// 	resenia: "La Neta del Planeta es la meta",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "EMPRENDIMIENTO Y NEGOCIOS",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
					// 	estudiantes: "2.584",
					// 	puntuacion: "3.8",
					// 	nombre: "MÓDULO 4",
					// 	resenia: "El Arte de dejar los vistos",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "LIDERAZGO",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
					// 	estudiantes: "842",
					// 	puntuacion: "4.9",
					// 	nombre: "MÓDULO 9",
					// 	resenia: "Funcionalidades de WhatsApp Business",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "LIDERAZGO",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
					// 	estudiantes: "1.456",
					// 	puntuacion: "4.7",
					// 	nombre: "MÓDULO 20",
					// 	resenia: "La Neta del Planeta es la meta",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "LIDERAZGO",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
					// 	estudiantes: "2.584",
					// 	puntuacion: "3.8",
					// 	nombre: "MÓDULO 21",
					// 	resenia: "El Arte de dejar los vistos",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "LIDERAZGO",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
					// {
					// 	src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
					// 	estudiantes: "842",
					// 	puntuacion: "4.9",
					// 	nombre: "MÓDULO 22",
					// 	resenia: "Funcionalidades de WhatsApp Business",
					// 	especialista: "Valentina Góngora",
					// 	sesiones: "2",
					// 	minutos: "137",
					// 	area: "CONOCIMIENTO TÉCNICO",
					// 	link: "https://niufleex.com/",
					// 	target: "_blank",
					// 	interna: "0",
					// 	boton: "ir al curso",
					// },
				],
			},
		};
	},
	components: {
		Cards2,
	},

	async mounted() {
		try {
			this.cardsCursosFree = {
				...this.cardsCursosFree,
				...(await this.$http.get(`/listacursos/${this.$route.params.team}`))
					.data[0],
			};
			// eslint-disable-next-line no-console
			console.log(this.cardsCursosFree);
		} catch (error) {
			this.cardsCursosFree = null;
		}
	},
	methods: {
		btnVerContenido_click() {
			let routeData = this.$router.resolve({
				path: this.cardsCursosFree.link_general,
			});
			if (this.interna_general == 1) {
				window.open(routeData.href, this.target_general);
			} else {
				window.open(this.cardsCursosFree.link_general, this.target_general);
			}
		},
	},
};
</script>