<template>
	<v-row>
		<v-col id="title-curso">
			<section
				v-if="
					($vuetify.breakpoint.name == 'xs' ||
						$vuetify.breakpoint.name == 'sm') &&
					!premium
				"
				class="flex-grow-0"
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
			>
				<ComprarAhoraXS></ComprarAhoraXS>
			</section>
			<section
				v-if="
					$vuetify.breakpoint.name != 'xs' &&
					$vuetify.breakpoint.name != 'sm' &&
					!premium
				"
				class="mt-3"
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
			>
				<ComprarAhoraMD></ComprarAhoraMD>
			</section>

			<section style="position:static">
				<BannerFree
					:datosbannerprincipal="datosbannerprincipal"
					:img="img"
					:continuar="continuar"
				/>
			</section>
			<section style="position:static">
				<Especialista :datosBannerEspecialista="datosBannerEspecialista" />
			</section>
			<section style="position:static">
				<Sesiones
					:trackCode="datosbannerprincipal.code"
					:datosSesiones="datosSesiones"
				/>
			</section>
			<!-- <Calificar /> -->
		</v-col>
	</v-row>
</template>
<script>
//BANNER SUPERIOR COMPRAR AHORA
import ComprarAhoraMD from "../components/shared/comprarahora.vue";
import ComprarAhoraXS from "../components/shared/comprarAhoraXS.vue";
import Especialista from "../components/ver_cursos/Especialista.vue";
import BannerFree from "../components/ver_cursos/bannerFree.vue";
import ToolbarPromo from "../components/ver_cursos/ToolbarPromo.vue";
import ToolbarPromoMobile from "../components/ver_cursos/ToolbarPromoMobile.vue";
import Sesiones from "../components/ver_cursos/Sesiones.vue";
// import apiVimeo from "../services/apiVimeo";
// import Calificar from "../components/ver_cursos/calificar.vue";
import { mapState } from "vuex";
export default {
	components: {
		ToolbarPromo,
		ToolbarPromoMobile,
		Especialista,
		BannerFree,
		Sesiones,
		ComprarAhoraMD,
		ComprarAhoraXS,
		// Calificar
	},
	data() {
		return {
			datosSesiones: null,
			datosbannerprincipal: {
				code: null,
			},
			datosBannerEspecialista: null,
			img: "",
			continuar: {
				area: "",
				especialista: "",
				estudiantes: "",
				idvideo: "",
				minutos: "",
				nombre: "",
				puntuacion: "",
				resenia: "",
				sesiones: "",
				src: "",
				trackCode: "",
				video_id: "",
				video_type_track_ruta: "",
			},
		};
	},
	async mounted() {
		try {
			// eslint-disable-next-line no-console
			console.log(this.$route.params);
			if (this.$route.params.guid) {
				let result = (
					await this.$http.get(`/listacursos/cursos/${this.$route.params.guid}`)
				).data;
				if (result) {
					// eslint-disable-next-line no-console
					console.group("ver_curso");
					this.datosBannerEspecialista = result.detalleEspecialistaCurso;
					this.datosSesiones = result.modulosDelCursoDTO;
					this.datosbannerprincipal = {
						...this.datosbannerprincipal,
						...result.datosDelCursoDTO,
					};
					// eslint-disable-next-line no-console
					console.log("sesiones", this.datosSesiones);
					this.$set(
						this.datosbannerprincipal,
						"video",
						this.datosSesiones[0].sesiones[0]
					);
					// eslint-disable-next-line no-console
					console.log(this.datosSesiones[0].sesiones[0]);
					this.datosbannerprincipal.cantmodulos = this.datosSesiones.length;
					var cantsesiones = 0;
					for (let i = 0; i < this.datosSesiones.length; i++) {
						cantsesiones += this.datosSesiones[i].sesiones.length;
					}
					this.datosbannerprincipal.cantsesiones = cantsesiones;
					//this.datosbannerprincipal.cargo =
					// eslint-disable-next-line no-console
					console.log("datosbannerprincipal", this.datosbannerprincipal);
					// eslint-disable-next-line no-console
					console.log("datosSesiones", this.datosSesiones);

					// eslint-disable-next-line no-console
					console.groupEnd("ver_curso");

					this.continuar = {
						...(
							await this.$http.post(`/videos/newlastviews`, {
								trackcode: this.$route.params.guid,
							})
						).data.items[0],
					};
					// eslint-disable-next-line no-console
					console.group("banner free");
					let idvideo =
						this.continuar["idvideo"] ||
						this.datosbannerprincipal.video.video_idvideovimeo;

					if (!this.continuar["nombre"]) {
						this.continuar["nombre"] =
							this.datosbannerprincipal.video.nombresesion;
					}
					if (!this.continuar["video_id"]) {
						this.continuar["video_id"] =
							this.datosbannerprincipal.video.video_id;
					}
					// eslint-disable-next-line no-console
					console.log(
						idvideo,
						this.continuar,
						this.datosbannerprincipal.video.video_idvideovimeo
					);

					// let uno = (await apiVimeo(idvideo)).data;
					// this.img = uno
					// 	.find((ele) => ele.active == true)
					// 	.sizes.find((ele) => ele.width == 1280).link;
					// // eslint-disable-next-line no-console
					// console.log(this.img, idvideo);
					// eslint-disable-next-line no-console
					console.groupEnd("banner free");
				}
			}
			// eslint-disable-next-line no-empty
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	},
	computed: {
		...mapState(["premium"]),
	},
};
</script>
<style scoped></style>
