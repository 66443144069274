<template>
	<v-card
		class="mx-auto"
		style="
			width: 100%;
			max-width: 100%;
			background-color: transparent;
			background-image: linear-gradient(90deg, #3e026e 11%, #710c68 100%);
		"
	>
		<v-img class="white--text align-end opa" :src="path">
			<v-row style="margin-top:-260px;">
				<v-col cols="12">
					<v-img
						class="rounded-xl"
						:src="img"
						style="height: 28px !important;
						margin-bottom: 25px;
    width: 80px !important;
    border-radius: 5px !important;
    margin-left: 16px;"
					></v-img>
					<v-card-title style="padding-bottom: 0px; z-index: 2">
						<v-row style="margin-bottom: 0px !important">
							<v-col cols="12" style="padding-bottom: 0; padding-top: 0">
								<h2
									style="
										color: white;
										font-family: 'Poppins', Sans-serif;
										font-size: 18px;
										font-weight: bold;
									"
								>
									<span v-html="titulo1"></span>
								</h2>
							</v-col>
							<v-col cols="12" style="padding-bottom: 0; padding-top: 0">
								<h2
									style="
										color: white;
										font-family: 'Raleway', Sans-serif;
										font-size: 14px;
										font-weight: 600;
									"
								>
									{{ subtitulo }}
								</h2>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-actions>
						<v-btn v-if="boton != ''" @click="btnConprarAhora_click" class="btninfo" style="margin-left: 10px;">{{
							boton
						}}</v-btn>
					</v-card-actions> 
				</v-col>
			</v-row>
		</v-img>
	</v-card>
</template>
<style>
.btninfo {
	font-family: "Raleway", Sans-serif !important;
	font-size: 12px !important;
	font-weight: 800 !important;
	color: #4b0b7f !important;
	background-color: #fac903 !important;
	border-color: #02010100 !important;
	padding: 15px 30px !important;
	height: 52px !important;
}
.btninfo:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
.opa::after {
	content: "";
	opacity: 0.7;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}
</style>
<script>
export default {
	props: {
		path: {
			type: [Number, String],
			default: "",
		},
		img: {
			type: [Number, String],
			default: "",
		},
		titulo1: {
			type: [Number, String],
			default: "",
		},
		titulo2: {
			type: [Number, String],
			default: "",
		},
		subtitulo: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
	},
	methods: {
		btnConprarAhora_click() {
			let routeData = this.$router.resolve({
				path: this.link,
			});
			if (this.interna == 1) {
				window.open(routeData.href, this.target);
			} else {
				window.open(this.link, this.target);
			}
		},
	},
};
</script>