<template>
	<v-row class="pa-2" justify="center">
		<v-col cols="12" md="10">
			<v-card
				class="mx-auto mt-4 pa-15"
				elevation="0"
				light
				color="grey lighten-4"
			>
				<v-row>
					<v-col cols="12" lg="6" sm="12" md="7" xl="6">
						<!-- <v-row class="pa-3">
							<label
								style="
									color: #3e026e;
									background-color: #ffab00;
									border-radius: 8px;
								"
							>
								Más vistos
							</label>
						</v-row> -->
						<v-row>
							<h2
								style="margin-top: 10px; font-size: 30px"
								:style="{
									color: theme.title,
								}"
							>
								{{ datosbannerprincipal.title }}
							</h2>
						</v-row>
						<v-row>
							<h2
								style="
									font-weight: normal;
									color: #525252;
									font-family: 'Raleway', sans-serif;
									font-size: 18px;
								"
							>
								Por {{ datosbannerprincipal.autor }}.
								{{ datosbannerprincipal.cargo || "" }}
							</h2>
						</v-row>
						<v-row class="mt-8">
							<v-icon> mdi-view-list </v-icon
							><label
								style="
									color: #908d9a;
									font-family: sans-serif;
									font-size: 15px;
									margin-left: 2px;
								"
							>
								{{ datosbannerprincipal.cantmodulos }} Módulos /
								{{ datosbannerprincipal.cantsesiones }} Sesiones
							</label>
							<v-icon class="ml-4"> mdi-timer </v-icon
							><label
								style="
									color: #908d9a;
									font-family: sans-serif;
									font-size: 15px;
									margin-left: 2px;
								"
							>
								{{ datosbannerprincipal.tiempo }}</label
							>
							<v-icon class="ml-4">mdi-message-processing</v-icon>
							<label
								style="
									color: #908d9a;
									font-family: sans-serif;
									font-size: 15px;
									margin-left: 2px;
								"
							>
								{{ datosbannerprincipal.idioma }}</label
							>
							<v-icon class="ml-4">mdi-closed-caption</v-icon>
							<label
								style="
									color: #908d9a;
									font-family: sans-serif;
									font-size: 15px;
									margin-left: 2px;
								"
							>
								{{ datosbannerprincipal.cc }}</label
							>
						</v-row>
						<v-row class="mt-8">
							<h2 style="color: #bdbdbd">Valoración del Curso</h2>
						</v-row>
						<v-row>
							<v-card-subtitle style="text-align: center; padding-top: 15px">
								<v-btn text class="fives" id="btnFiveStar1">
									<i class="fas fa-gem" style="font-size: 22px"></i>
								</v-btn>

								<v-btn text class="fives" id="btnFiveStar2">
									<i class="fas fa-gem" style="font-size: 22px"></i>
								</v-btn>

								<v-btn text class="fives" id="btnFiveStar3">
									<i class="fas fa-gem" style="font-size: 22px"></i>
								</v-btn>

								<v-btn text class="fives" id="btnFiveStar4">
									<i class="fas fa-gem" style="font-size: 22px"></i>
								</v-btn>

								<v-btn text class="fives" id="btnFiveStar5">
									<i class="fas fa-gem" style="font-size: 22px"></i>
								</v-btn>
							</v-card-subtitle>

							<!-- <v-rating length="5" size="20px" value="5">
								<template v-slot:item="props">
									<v-icon
										large
										:color="props.isFilled ? 'yellow darken-1' : ''"
									>
										mdi-diamond-stone
									</v-icon>
								</template>
							</v-rating> -->
						</v-row>
						<v-row class="mt-10">
							<v-btn
								height="50"
								width="180"
								style="
									content-align: justify;
									margin-top: 0px;
									padding: 20px 25px 17px;
									margin-left: 0px;
									font-size: 18px;
									color: white;

									text-transform: capitalize;
								"
								:style="{
									backgroundColor: theme.btnFicha,
								}"
								:to="`/play/${datosbannerprincipal.code}#${continuar.video_id}`"
								><b> ¡Ver el Curso! </b></v-btn
							>
						</v-row>
					</v-col>
					<v-col   cols="12" lg="5" sm="12" md="5" xl="5">
						<v-card
							style="
								border-bottom-left-radius: 20px;
								border-bottom-right-radius: 20px;
							"
							:style="{
								backgroundColor: theme.btnFicha,
							}"
						>
							<router-link
								:to="`/play/${datosbannerprincipal.code}#${continuar.video_id}`"
								style="text-decoration: none"
							>
								<v-hover>
									<v-img :src="datosbannerprincipal.src" slot-scope="{ hover }" class="img">
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-btn
													v-if="hover"
													outlined
													fab
													color="white"
													height="90"
													width="90"
													style="border: 4px solid currentColor"
													><v-icon x-large> mdi-play </v-icon></v-btn
												>
											</v-row>
										</v-container>
									</v-img>
								</v-hover>
							</router-link>

							<v-row class="ml-xl-4">
								<v-col cols="12" style="text-align: left">
									<v-card-title style="color: white">
										<v-row>
											<v-col>
												<p
													class="ml-6"
													style="
														color: white;
														font-family: 'Poppins', sans-serif;
														font-size: 25px;
														font-weight: bold;
													"
												>
													<v-btn outlined fab color="#FB950C">
														<v-icon style="font-size: 28px">
															mdi-bookmark-outline
														</v-icon>
													</v-btn>
													<span
														style="
															color: #FFFFFFF;
															position: relative;
															left: 5px;
															color: white;
															font-family: 'Raleway', sans-serif;
															font-size: 18px;
															word-break: break-word;
														"
														>Continuar Reproduciendo</span
													>
												</p>
												<span
													style="
														-webkit-line-clamp: 3;
														-webkit-box-orient: vertical;
														overflow: hidden;
														text-overflow: ellipsis;
														word-break: break-word;
													"
												>
													Sesión: {{ continuar.nombre }}
												</span>
											</v-col>
										</v-row>
									</v-card-title>
								</v-col>
							</v-row>
						</v-card>

						<!-- <v-row class="ml-4">
							<v-col cols="12" lg="2" sm="8" md="9">
								<v-btn id="bookFree">
									<v-icon id="redondaFree" style="font-size: 28px">
										mdi-bookmark-outline
									</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="12" lg="8" sm="8" md="9">
								<v-card-title style="color: white"> Trailer </v-card-title>

								<v-card-subtitle style="color: white"> </v-card-subtitle>
							</v-col>
						</v-row> -->
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
import { mapState } from "vuex";
export default {
	props: {
		datosbannerprincipal: {
			type: Object,
			default: null,
		},
		img: {
			type: String,
			default: "",
		},
		continuar: {
			type: Object,
			default: () => {
				return {
					area: "",
					especialista: "",
					estudiantes: "",
					idvideo: "",
					minutos: "",
					nombre: "",
					puntuacion: "",
					resenia: "",
					sesiones: "",
					src: "",
					trackCode: "",
					video_id: "",
					video_type_track_ruta: "",
				};
			},
		},
	},
	async created() {},
	computed: {
		...mapState(["theme"]),
	},
	data() {
		return {
			videoplayer: null,
			banners: bannersData,
			pasoUpdate: false,
			images: {
				sample: require("../../assets/video.png"),
			},
		};
	},
};
</script>
<style scoped>
.fives {
	padding: 10px !important;
	border: none !important;
	min-width: 0 !important;
	padding: 5px !important;
	color: #f3c660 !important;;
}
.fives:hover {
	background-color: transparent !important;
	color: #f3c660;
	opacity: 1;
}
#bookFree {
	background-color: transparent;
	box-shadow: none;
	color: rgb(236, 118, 6);
	margin-top: 0px;
	height: 80px;
	width: 10%;
}
#bookFree:hover {
	box-shadow: none;
	color: rgb(236, 118, 6);
}
#redondaFree {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: solid 3px rgb(236, 118, 6);
	overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
#redondaFree:hover {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: solid 3px rgb(236, 118, 6);
	overflow: hidden; /* Evitar que el contenido se visualice fuera del borde*/
}
.img:hover {
	opacity: 0.7;
}
</style>
