<template>
	<v-row style="margin-top: -10px">
		<v-col cols="12" lg="12" sm="10" md="9">
			<v-card color="purple darken-3">
				<v-row class="text-center">
					<v-col>
						<h1 style="color: white" class="mt-6">Nuestros programas</h1>
					</v-col>
				</v-row>
				<v-row class="pa-15">
					<v-col cols="12" lg="4" sm="10" md="9">
						<v-card
							class="mx-auto"
							max-width="344"
							color="purple darken-4"
							elevation="6"
						>
							<v-img :src="images.sample" height="200px">
								<h2
									style="
										border-radius: 8px;
										font-family: sans-serif;
										color: white;
										height: 30px;
										font-size: 14px;
										text-align: center;
										width: 60px;
										padding: 6px;
										background-color: #4a148c;
									"
									class="ml-2 mt-2"
								>
									Free
								</h2>
							</v-img>

							<v-card-title style="color: white">
								{{ banners.Programa[0].class }}
							</v-card-title>

							<v-card-subtitle style="color: white">
								{{ banners.Programa[0].tipo }}
							</v-card-subtitle>
							<v-card-subtitle style="color: white">
								{{ banners.Programa[0].autor }}
							</v-card-subtitle>
							<v-row class="mt-0 ml-3">
								<v-icon style="color: white"> mdi-view-list </v-icon>
								<!-- <h1 class="mt-1" style="font-size: 14px; color: white">
                  {{ banners.Programa[0].cantcapitulos }}
                </h1> -->
								<v-icon class="ml-3" style="color: white">
									mdi-eye-outline
								</v-icon>
								<h1 class="mt-1 ml-1" style="font-size: 14px; color: white">
									{{ banners.Programa[0].Vistas }}
								</h1>
							</v-row>
							<v-card-actions class="mt-3">
								<v-btn
									class="ma-0"
									outlined
									color="white"
									text
									block
									style="
										font-weight: bold;
										text-transform: capitalize;
										border: solid white 3px;
									"
                  :href="banners.Programa[0].link"
                  target="_blank"
								>
									ver Trasmisiones
								</v-btn>

								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-col>

					<v-col cols="12" lg="4" sm="10" md="9">
						<v-card
							class="mx-auto"
							max-width="344"
							color="purple darken-4"
							elevation="6"
						>
							<v-img :src="images.sample" height="200px">
								<h2
									style="
										border-radius: 8px;
										font-family: sans-serif;
										color: white;
										height: 30px;
										font-size: 14px;
										text-align: center;
										width: 60px;
										padding: 6px;
										background-color: #4a148c;
									"
									class="ml-2 mt-2"
								>
									Free
								</h2>
							</v-img>

							<v-card-title style="color: white">
								{{ banners.Programa2[0].class }}
							</v-card-title>

							<v-card-subtitle style="color: white">
								{{ banners.Programa2[0].tipo }}
							</v-card-subtitle>
							<v-card-subtitle style="color: white">
								{{ banners.Programa2[0].autor }}
							</v-card-subtitle>
							<v-row class="mt-0 ml-3">
								<v-icon style="color: white"> mdi-view-list </v-icon>
								<!-- <h1 class="mt-1" style="font-size: 14px; color: white">
                  {{ banners.Programa2[0].cantcapitulos }}
                </h1> -->
								<v-icon class="ml-3" style="color: white">
									mdi-eye-outline
								</v-icon>
								<h1 class="mt-1 ml-1" style="font-size: 14px; color: white">
									{{ banners.Programa2[0].Vistas }}
								</h1>
							</v-row>
							<v-card-actions class="mt-3">
								<v-btn
									class="ma-0"
									outlined
									color="white"
									text
									block
									style="
										font-weight: bold;
										text-transform: capitalize;
										border: solid white 3px;
									"
									:href="banners.Programa2[0].link"
                  target="_blank"
								>
									ver Trasmisiones
								</v-btn>

								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-col>

					<v-col cols="12" lg="4" sm="10" md="9">
						<v-card
							class="mx-auto"
							max-width="344"
							color="purple darken-4"
							elevation="6"
						>
							<v-img :src="images.sample" height="200px">
								<h2
									style="
										border-radius: 8px;
										font-family: sans-serif;
										color: white;
										height: 30px;
										font-size: 14px;
										text-align: center;
										width: 60px;
										padding: 6px;
										background-color: #4a148c;
									"
									class="ml-2 mt-2"
								>
									Free
								</h2>
							</v-img>

							<v-card-title style="color: white">
								{{ banners.Programa3[0].class }}
							</v-card-title>

							<v-card-subtitle style="color: white">
								{{ banners.Programa3[0].tipo }}
							</v-card-subtitle>
							<v-card-subtitle style="color: white">
								{{ banners.Programa3[0].autor }}
							</v-card-subtitle>
							<v-row class="mt-0 ml-3">
								<v-icon style="color: white"> mdi-view-list </v-icon>
								<!-- <h1 class="mt-1" style="font-size: 14px; color: white">
                  {{ banners.Programa3[0].cantcapitulos }}
                </h1> -->
								<v-icon class="ml-3" style="color: white">
									mdi-eye-outline
								</v-icon>
								<h1 class="mt-1 ml-1" style="font-size: 14px; color: white">
									{{ banners.Programa3[0].Vistas }}
								</h1>
							</v-row>
							<v-card-actions class="mt-3">
								<v-btn
									class="ma-0"
									outlined
									color="white"
									text
									block
									style="
										font-weight: bold;
										text-transform: capitalize;
										border: solid white 3px;
									"
									:href="banners.Programa3[0].link"
                  target="_blank"
								>
									ver Trasmisiones
								</v-btn>

								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
export default {
	data() {
		return {
			banners: bannersData,
			images: {
				sample: require("../../assets/minisergio.png"),
			},
		};
	},
};
</script>
<style scoped></style>
