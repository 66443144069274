<template>
	<v-container style="padding-top: 40px" v-if="cardsContRep.items.length>0">
		<v-row>
			<v-col
				cols="3"
				style="
					text-align: center;
					padding-left: 10px;
					padding-left: 50px;
					padding-right: 0px;
				"
			>
				<v-icon
					style="
						width: 53px !important;
						margin-top: 50px;
						height: 53px !important;
						margin-bottom: 20px !important;
						background-color: rgb(106 40 159) !important;
						color: rgb(251, 149, 12) !important;
						border-radius: 50% !important;
						border: solid #fb950c !important;
					"
					>mdi-bookmark</v-icon
				>
				<h2
					style="
						color: #ffffff !important;
						font-family: 'Poppins', Sans-serif !important;
						font-size: 30px !important;
						font-weight: 600 !important;
						line-height: 1em !important;
						margin-bottom: 20px !important;
						letter-spacing: -0.7px !important;
					"
				>
					Continuar reproduciendo  
				</h2>
				<!-- <p
					style="
						color: #fb950c;
						font-family: 'Raleway', Sans-serif;
						font-size: 17px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
						margin-bottom: 20px !important;
					"
				>
					Ordenar por
				</p>
				<v-combobox
					v-model="select"
					:items="itemscombo"
					light
					outlined
					dense
				></v-combobox>
				<v-btn style="background-color: #fb950c; color: #ffffff">VER</v-btn> -->
			</v-col>
			<v-col cols="9">
				<v-flex>
					<v-carousel
						hide-delimiters
						style="box-shadow: 0px 0px"
						class="csscards1MD"
						v-if="cardsContRep && cardsContRep['items']"
					>
						<v-carousel-item
							v-for="i in Math.ceil(cardsContRep.items.length / 3)"
							:key="i"
						>
							<v-layout row>
								<v-flex sm4 pl-2 pr-2>
									<Cards1
										v-if="i * 3 - 3 < cardsContRep.items.length"
										:path="cardsContRep.items[i * 3 - 3].src"
										:estudiantes="cardsContRep.items[i * 3 - 3].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 3].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 3].nombre"
										:resenia="cardsContRep.items[i * 3 - 3].resenia"
										:especialista="cardsContRep.items[i * 3 - 3].especialista"
										:sesiones="cardsContRep.items[i * 3 - 3].sesiones"
										:minutos="cardsContRep.items[i * 3 - 3].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 3].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 3].trackCode"
										:video_id="cardsContRep.items[i * 3 - 3].video_id"
									></Cards1>
								</v-flex>
								<v-flex sm4 pl-2 pr-2>
									<Cards1
										v-if="i * 3 - 2 < cardsContRep.items.length"
										:path="cardsContRep.items[i * 3 - 2].src"
										:estudiantes="cardsContRep.items[i * 3 - 2].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 2].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 2].nombre"
										:resenia="cardsContRep.items[i * 3 - 2].resenia"
										:especialista="cardsContRep.items[i * 3 - 2].especialista"
										:sesiones="cardsContRep.items[i * 3 - 2].sesiones"
										:minutos="cardsContRep.items[i * 3 - 2].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 2].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 2].trackCode"
										:video_id="cardsContRep.items[i * 3 - 2].video_id"
									></Cards1>
								</v-flex>
								<v-flex sm4 pl-2 pr-2>
									<Cards1
										v-if="i * 3 - 1 < cardsContRep.items.length"
										:path="cardsContRep.items[i * 3 - 1].src"
										:estudiantes="cardsContRep.items[i * 3 - 1].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 1].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 1].nombre"
										:resenia="cardsContRep.items[i * 3 - 1].resenia"
										:especialista="cardsContRep.items[i * 3 - 1].especialista"
										:sesiones="cardsContRep.items[i * 3 - 1].sesiones"
										:minutos="cardsContRep.items[i * 3 - 1].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 1].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 1].trackCode"
										:video_id="cardsContRep.items[i * 3 - 1].video_id"
									></Cards1>
								</v-flex>
							</v-layout>
						</v-carousel-item>
					</v-carousel>
					<!-- <p
						style="
							margin-top: -105px;
							text-align: right;
							margin-right: 20%;
							color: #ffffff;
							font-family: 'Poppins', Sans-serif;
							font-size: 18px;
							font-weight: 600;
							line-height: 1.2em;
							letter-spacing: -0.7px;
						"
					>
						{{ this.cardsContRep.cantPendientes }} de
						{{ this.cardsContRep.totCurso }} Módulos pendientes
					</p> -->
				</v-flex>
			</v-col>
		</v-row>
	</v-container>
</template>
<style lang="scss">
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
	cursor: text;
	background-color: white;
}
.csscards1MD .v-window__prev {
	margin-top: 150px !important;
	margin-left: 82% !important;
}
.csscards1MD .v-window__next {
	margin-top: 150px !important;
	margin-right: 2% !important;
}
.csscards1MD .v-window__prev .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: #6a289f !important;
	border: solid !important;
}
.csscards1MD .v-window__prev .v-btn{

}
.csscards1MD .v-window__next .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: #6a289f !important;
	border: solid !important;
}
.csscards1MD .v-responsive__content .layout {
	padding-right: 35px !important;
}
</style>
<script>
import Cards1 from "./CardsContinuarReproduciendo.vue";
import apiVimeo from "../../../services/apiVimeo";
export default {
	data() {
		return {
			indice: 0,
			select: ["Fecha"],
			itemscombo: ["Fecha", "Minutos reproducidos"],
			cardsContRep: {
				items: [],
			},
			cardsContRepNew: {
				totCurso: "10",
				cantPendientes: "3",
				items: [
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 1",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 4",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 9",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 20",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 21",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 22",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 23",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
				],
			},
		};
	},
	components: {
		Cards1,
	},
	async mounted() {
		this.cardsContRep = {
			...this.cardsContRep,
			...(await this.$http.get(`/videos/newlastviews`)).data,
		};
		// eslint-disable-next-line no-console
		console.log(this.cardsContRep)
		for (const element of this.cardsContRep.items) {
			let uno = (await apiVimeo(element.idvideo)).data;
			element.src = uno
				.find((ele) => ele.active == true)
				.sizes.find((ele) => ele.width == 1280).link;
		}
		// eslint-disable-next-line no-console
		console.log(this.cardsContRep);
		window.cars = this.cardsContRep;
	},
	methods: {
		contador() {
			parseInt(this.indice++);
		},
	},
};
</script>