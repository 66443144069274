<template>
	<v-row :align="'center'" :justify="'center'" class="mb-15">
		<v-col lg="3" md="4" sm="6" xs="10">
			<v-form
				v-model="valid"
				ref="form"
				@submit.prevent="validate"
				:lazy-validation="lazy"
				class="rounded-lg rounded-xl rounded elevation-15"
			>
				<v-card
					style="background-color: #f5f5f5"
					class="rounded-lg rounded-xl rounded elevation-15"
				>
					<v-card-text class="ma-0 pa-0">
						<v-row class="ma-0 pa-0">
							<v-col cols="12" class="ma-0 pa-0">
								<v-row justify="center" class="ma-0 pa-0 mt-4">
									<v-col cols="9" class="ma-0 pa-0">
										<v-img
											class="ma-0 pa-0"
											src="../assets/weblogo.png"
											lazy-src="../assets/client-loader.gif"
											width="100%"
										></v-img>
									</v-col>
								</v-row>
								<v-row v-if="$route.query.validate_team" justify="center">
									<v-col cols="10">
										<div v-if="$route.query.validate_team == 'teomachile'">
											<label
												:style="`color:${$vuetify.theme.defaults.light.primary}; font-weight: bold;`"
												>Te estás uniendo a</label
											>
											<img
												width="80%"
												src="https://niufleex.com/wp-content/uploads/2021/10/48HRS-ChileDistintivo.png"
												alt=""
											/>
										</div>
									</v-col>
								</v-row>
								<v-row justify="center">
									<v-col cols="10" style="text-align: center">
										<v-row>
											<v-col>
												<h2 class="ma-0 pa-0 pb-2" style="line-height: 18px">
													¡Bienvenid@!
												</h2>
												<v-text-field
													label
													placeholder="Usuario"
													color="#ffffff"
													v-model="form.username"
													required
												></v-text-field>
												<v-text-field
													label
													placeholder="Contraseña"
													v-model="form.password"
													:append-icon="verpass ? 'mdi-eye' : 'mdi-eye-off'"
													@click:append="() => (verpass = !verpass)"
													:type="!verpass ? 'password' : 'text'"
													required
												></v-text-field>
												<v-btn
													v-if="!showProgress"
													:loading="showProgress"
													block
													class="niu-background-principal-1 mr-4 mt-3 niu-input-placeholder rounded-lg rounded-xl rounded niu-luchoambiado"
													type="submit"
												>
													Inicia Sesión
												</v-btn>
												<v-progress-circular
													v-else
													indeterminate
													color="primary"
												></v-progress-circular>
												<v-btn
													:loading="showProgress"
													block
													color="blue"
													class="mr-4 mt-3 niu-input-placeholder rounded-lg rounded-xl rounded niu-luchoambiado"
													@click="login_auth0"
												>
													<span v-if="!$auth.isAuthenticated">
														Usuarios Free con Facebook {{ auth0_login }}
													</span>
													<span v-else> Iniciando con Facebook </span>
													<span style="display: none">{{ auth0_login }}</span>
												</v-btn>
											</v-col>
										</v-row>
										<v-row class="ma-0 pa-0 mt-3">
											<v-col cols="12" class="ma-0 pa-0">
												<div class="niu-checkbox ma-0 pa-0">
													<div
														class="niu-content-checkbox niu-luchoambiado"
														:class="{ 'niu-checkbox-active': checkbox1 }"
														@click="checkbox1 = !checkbox1"
													>
														<div v-if="checkbox1">
															<v-icon>mdi-checkbox-marked</v-icon>
														</div>
														<div v-else>
															<v-icon>mdi-checkbox-blank-outline</v-icon>
														</div>
														<input
															class="niu-checkbox-hide"
															type="checkbox"
															v-model="checkbox1"
														/>
													</div>
													<label
														@click="checkbox1 = !checkbox1"
														class="niu-luchoambiado"
														style="color: black !important"
													>
														Recuérdame
													</label>
												</div>
											</v-col>
											<v-col cols="12" class="ma-0 pa-0">
												<v-hover v-slot:default="{ hover }" disabled>
													<v-btn
														href="https://registro.niufleex.com/olvidaste"
														class="niu-link-recuperar transparent elevation-0 niu-luchoambiado"
														:class="{ 'niu-hover': hover }"
														style="color: black !important"
													>
														¿Olvidaste tu contraseña?
													</v-btn>
												</v-hover>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions style="background-color: #fff" class="ma-0 pa-0">
						<v-row class="ma-0 pa-0 m-4">
							<v-col cols="12" class="ma-0 pa-0">
								<v-row class="ma-0 pa-0">
									<v-col class="ma-0 pa-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row justify="center">
									<v-col cols="10">
										<v-btn
											:loading="showProgress"
											block
											class="niu-background-principal-2 rounded-lg rounded-xl rounded niu-luchoambiado"
											href="https://registro.niufleex.com"
											style="color: white"
										>
											Regístrate
										</v-btn>
									</v-col>
								</v-row>
								<v-row style="text-align: center">
									<v-col cols="10" md="6">
										<v-btn
											target="_blank"
											href="https://niufleex.com/terminos-y-condiciones/"
										>
											Terminos y Condiciones
										</v-btn>
									</v-col>
								</v-row>
								<v-row style="text-align: center">
									<v-col cols="10" md="6">
										<v-btn
											style="text-align: center"
											target="_blank"
											href="https://niufleex.com/politicas-de-privacidad/"
										>
											Polícitas de Privacidad
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-form>
			<registerFacebook
				:dialog="dialogFacebook"
				@closeDialogFacebook="closeDialogFacebook"
			></registerFacebook>
		</v-col>
	</v-row>
</template>
<script>
	/* eslint-disable no-console */
	// import { mapActions } from 'vuex'
	// import webapi from '../webapi/webapi'
	// import Pusher from 'pusher-js/with-encryption'
	import registerFacebook from '../components/login/registerFacebook.vue';
	export default {
		name: 'Login',
		components: {
			registerFacebook,
		},
		data() {
			return {
				dialogFacebook: false,
				pasoAuth: false,
				verpass: false,
				ip: '',
				authMode: 'jwt',
				authOptions: [
					{ text: 'Cookie', value: 'cookie' },
					{ text: 'JWT Bearer', value: 'jwt' },
				],
				valid: true,
				form: { username: '', password: '', days: 1 },
				submitted: false,
				loggingIn: '',
				checkbox: false,
				checkbox1: false,
				lazy: true,
				showProgress: false,
				ttl1day: { ttl: 60 * 60 * 1000 * 24 },
				ttl30day: { ttl: 60 * 60 * 1000 * 24 * 30 },
				messages: '',
			};
		},
		created() {
			this.$auth.user = null;
			this.$storeNiu.set('token', this.$route.query.access_token);
			this.$storeNiu.set('user', this.$route.query.user);

			this.$storeNiu.set('validar_team', this.$route.query.team);
			this.$vuetify.theme.dark = false;
			if (window.location.href == 'https://premium.niufleex.com/login') {
				window.location.href = 'https://app.niufleex.com/login';
			}
			// let pusher = new Pusher('d7769fba029c3e0c7dff', {
			//   cluster: 'us2'
			// })

			// let channel = pusher.subscribe('my-channel')
			// channel.bind('my-event', data => {
			//   this.messages.push(JSON.stringify(data))
			// })
			if (this.$route.query.logout == 'true') {
				this.logout();
			}
		},
		mounted() {
			// console.log(this.$route, this.$router);
		},
		updated() {
			console.log(this.$auth);
			if (
				this.$auth.isAuthenticated &&
				!this.pasoAuth &&
				this.$auth.user &&
				this.$auth.user.sub
			) {
				// this.obtenerCode();
				// eslint-disable-next-line no-debugger
				// debugger;
				this.pasoAuth = true;
				// alert("paso auth0", this.$auth.user.sub);
				console.log('paso auth0', this.$auth.user);
				console.log(this.$auth.isAuthenticated);
				let query = '';
				this.showProgress = true;
				if (!this.checkbox1) {
					this.form.days = 30;
				}
				let nav = this.$getBrowserInfo();
				let ipLogin = '';

				this.$http
					.post('/users/authenticate', {
						username: this.$auth.user.sub.replace('|', ''),
						password: '-1',
						historial: {
							ipprivada: ipLogin || '',
							navegador: this.$storeNiu.getTextOrInt('navegador') || nav || '',
							vendor: window.navigator.vendor || '',
							styleMedia: '',
							appVersion: window.navigator.appVersion || '',
							platform: window.navigator.platform || '',
							userAgent: window.navigator.userAgent || '',
							// eslint-disable-next-line no-extra-boolean-cast
							subnav: !!navigator.brave
								? 'brave'
								: // eslint-disable-next-line no-extra-boolean-cast
								!!window.chrome
								? 'chrome'
								: '',
							baseURL: this.$http.defaults.baseURL,
						},
					})
					// this.login({ authMethod: this.authMode, credentials: this.form })
					.then((result) => {
						if (result) {
							window.resultlogin = result;
							this.$storeNiu.set('token', result.data.token);

							this.$storeNiu.set('data', result.data);

							this.$storeNiu.set('user', result.data.username);
							this.$storeNiu.set('team_guid', result.data.team_guid);
							this.$storeNiu.set('team', result.data.team_code);

							this.gtag('set', { user_id: result.data.username }); // Establezca el ID de usuario mediante el user_id con el que haya iniciado sesión.
							this.$store.commit('users', result.data);
						}
						this.$store.commit('alert', {
							type: 'niu-background-principal-1',
							message: `Bienvenido ${result.data.firstname} ${result.data.firstsurname}`,
							snackbar: true,
						});
						// let redirect = null
						let validate_team = null;
						let verified_email = null;

						// if (this.$route.query['redirect']) {
						//   redirect = this.$route.query.redirect
						// }
						if (this.$route.query['validate_team']) {
							validate_team = this.$route.query.validate_team;
						}
						if (this.$route.query['verified_email']) {
							verified_email = this.$route.query.verified_email;
						}

						if (verified_email) {
							query = `verified_email=${verified_email}`;
						}
						if (validate_team) {
							query = query != '' ? '&' : '';
							query = `validate_team=${validate_team}`;
						}

						// eslint-disable-next-line no-console
						// console.log(this.$route)
						if (this.$route.query.orderid && this.$route.query.acceso) {
							this.$router.push(
								`/yo/${this.$storeNiu.getTextOrInt(
									'user',
								)}?renueva=membresia&orderid=${
									this.$route.query.orderid
								}&acceso=${this.$route.query.acceso}`,
							);
						} else {
							if (this.$route.query.redirect) {
								this.$router.push(`${this.$route.query.redirect}`);
							} else {
								let home = result.data.team_code;
								// alert(home)
								if (result.data.membership_id == 2) {
									if (window.location.host == 'app.niufleex.com') {
										home = process.env.VUE_APP_MAINPREMIUM;
										window.location.href = `https://premium.niufleex.com/${home}?access_token=${result.data.token}&user=${result.data.username}&${query}`;
									} else {
										if (query != '') {
											query = '?' + query;
										}
										home = process.env.VUE_APP_MAINPREMIUM;
										this.$router.push(`/${home}${query}`);
									}
									// this.$router.push({ name: 'explora_premium' })
								} else {
									if (result.data.membership_id == 1) {
										if (query != '') {
											query = '?' + query;
										}
										home = process.env.VUE_APP_MAINFREE;
										this.$router.push(`/${home}${query}`);
									} else {
										home = process.env.VUE_APP_MAINFREE;
										this.$router.push(`/${home}`);
									}
								}
							}
						}
					})
					//eslint-disable-next-line
					.catch((e) => {
						this.$emit('menufloat');
						this.$storeNiu.clear();
						if (e.response.data.code != 'facebook') {
							this.$store.commit('alert', {
								type: 'rojo',
								message:
									'Usuario o contraseña incorrecta.<br> ¡Inténtalo de nuevo por favor!', //+ e.response.data['message'],
								snackbar: true,
								moreinfo: true,
								timeout: 10000,
								adicional: false,
								msmAdicional: `${JSON.stringify(e)} <br/> ${
									this.$http.defaults.baseURL
								}`,
							});
							this.showProgress = false;
						} else {
							if (e.response.data.code == 'facebook') {
								this.dialogFacebook = true;
							}
						}

						console.log(e.response);
					});
			}
		},
		computed: {
			auth0_login() {
				return this.$auth.user ? this.$auth.user.sub : '';
			},
		},
		methods: {
			closeDialogFacebook() {
				this.dialogFacebook = false;
			},
			textToUpper(val) {
				this.form.username = val.toLowerCase();
			},
			async validate() {
				// let linkquery = ''
				let query = '';
				this.showProgress = true;

				if (this.$refs.form.validate()) {
					if (!this.checkbox1) {
						this.form.days = 30;
					}
					let nav = this.$getBrowserInfo();
					let ipLogin = '';

					try {
						let result = await this.$http.post('/users/authenticate', {
							...this.form,
							pass: this.form.password,
							password: this.$md5(
								this.$md5(this.utf8_to_b64(this.form.password)),
							),

							historial: {
								ipprivada: ipLogin || '',
								navegador:
									this.$storeNiu.getTextOrInt('navegador') || nav || '',
								vendor: window.navigator.vendor || '',
								styleMedia: '',
								appVersion: window.navigator.appVersion || '',
								platform: window.navigator.platform || '',
								userAgent: window.navigator.userAgent || '',
								// eslint-disable-next-line no-extra-boolean-cast
								subnav: !!navigator.brave
									? 'brave'
									: // eslint-disable-next-line no-extra-boolean-cast
									!!window.chrome
									? 'chrome'
									: '',
								baseURL: this.$http.defaults.baseURL,
							},
						});
						// this.login({ authMethod: this.authMode, credentials: this.form })

						if (result) {
							window.resultlogin = result;
							this.$storeNiu.set('token', result.data.token);

							this.$storeNiu.set('data', result.data);

							this.$storeNiu.set('user', result.data.username);
							this.$storeNiu.set('team_guid', result.data.team_guid);
							this.$storeNiu.set('team', result.data.team_code);

							this.gtag('set', { user_id: result.data.username }); // Establezca el ID de usuario mediante el user_id con el que haya iniciado sesión.
							this.$store.commit('users', result.data);
						}
						this.$store.commit('alert', {
							type: 'niu-background-principal-1',
							message: `Bienvenido ${result.data.firstname} ${result.data.firstsurname}`,
							snackbar: true,
						});
						// let redirect = null
						let validate_team = null;
						let verified_email = null;

						// if (this.$route.query['redirect']) {
						//   redirect = this.$route.query.redirect
						// }
						if (this.$route.query['validate_team']) {
							validate_team = this.$route.query.validate_team;
						}
						if (this.$route.query['verified_email']) {
							verified_email = this.$route.query.verified_email;
						}

						if (verified_email) {
							query = `verified_email=${verified_email}`;
						}
						if (validate_team) {
							query = query != '' ? '&' : '';
							query = `validate_team=${validate_team}`;
						}

						// eslint-disable-next-line no-console
						// console.log(this.$route)
						if (this.$route.query.orderid && this.$route.query.acceso) {
							this.$router.push(
								`/yo/${this.$storeNiu.getTextOrInt(
									'user',
								)}?renueva=membresia&orderid=${
									this.$route.query.orderid
								}&acceso=${this.$route.query.acceso}`,
							);
						} else {
							if (this.$route.query.redirect) {
								this.$router.push(`${this.$route.query.redirect}`);
							} else {
								let home = result.data.team_code;
								// alert(home)
								if (result.data.membership_id == 2) {
									if (window.location.host == 'app.niufleex.com') {
										home = process.env.VUE_APP_MAINPREMIUM;
										window.location.href = `https://premium.niufleex.com/${home}?access_token=${result.data.token}&user=${result.data.username}&${query}`;
									} else {
										if (query != '') {
											query = '?' + query;
										}
										home = process.env.VUE_APP_MAINPREMIUM;
										this.$router.push(`/${home}${query}`);
									}
									// this.$router.push({ name: 'explora_premium' })
								} else {
									if (result.data.membership_id == 1) {
										if (query != '') {
											query = '?' + query;
										}
										home = process.env.VUE_APP_MAINFREE;
										this.$router.push(`/${home}${query}`);
									} else {
										home = process.env.VUE_APP_MAINFREE;
										this.$router.push(`/${home}`);
									}
								}
							}
						}
					} catch (e) {
						this.$emit('menufloat');
						this.$storeNiu.clear();
						this.$store.commit('alert', {
							type: 'rojo',
							message:
								'Usuario o contraseña incorrecta.<br> ¡Inténtalo de nuevo por favor!', //+ e.response.data['message'],
							snackbar: true,
							moreinfo: true,
							timeout: 10000,
							adicional: false,
							msmAdicional: `${JSON.stringify(e)} <br/> ${
								this.$http.defaults.baseURL
							}`,
						});
						this.showProgress = false;
					}
				} else {
					this.showProgress = false;
				}
			},
			utf8_to_b64(str) {
				return window.btoa(unescape(encodeURIComponent(str)));
			},
			login_auth0() {
				this.$auth.loginWithRedirect();
			},
			logout() {
				this.$auth.logout({ returnTo: window.location.origin + '/login' });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.theme--dark.v-divider {
		border-color: white;
	}
	.login {
		min-height: 500px;
		border-radius: 4px;
		color: #fff;
	}

	.niu-background-principal-1 {
		background: rgb(89, 13, 83) !important;
		background: linear-gradient(
			45deg,
			rgba(89, 13, 83, 1) 0%,
			rgba(165, 40, 130, 1) 100%
		) !important;
	}
	.niu-background-principal-2 {
		background: #f64600;
		background: linear-gradient(45deg, #f64600 0%, #f39d00 100%);
	}
	.v-input--selection-controls {
		margin: 0;
	}
	.niu-link-recuperar.niu-hover {
		background: blue;
	}
	.niu-checkbox-hide {
		display: none;
	}
	.niu-luchoambiado {
		text-transform: none;
		font-size: 1rem;
		letter-spacing: normal;
		font-weight: 400;
	}
	.niu-content-checkbox,
	.niu-content-checkbox > div {
		display: inline-block;
	}

	.niu-input-placeholder ::placeholder,
	.niu-input-placeholder {
		color: white !important;
	}
	.niu-input-placeholder.theme--light.v-input input,
	.niu-input-placeholder.theme--light.v-input textarea {
		color: white !important;
	}
</style>
