<template>
  <v-row>
    <v-col cols="12" lg="12" sm="12" md="9">
      <v-footer dark padless>
        <v-card class="flex" flat color="#4b0b7f" tile height="320">
          <div style="text-align: center" class="pa-8">
            <v-row class="mt-2 pa-12" style="text-align: center">
              <v-col cols="12" lg="3" sm="12" md="9">
                <div class="fontnormal">
                  <p class="font">Compañía</p>
                  <p>NIUFLEEX</p>
                  <p>TEOMA</p>
                  <p>NIUFLEEX para EITa</p>
                  <p>Membresía PREMIUM</p>
                </div>
              </v-col>
              <v-col cols="12" lg="3" sm="12" md="9">
                <div class="fontnormal">
                  <p class="font">NIUFLEEX</p>
                  <p>Cursos</p>
                  <p>Masterclass</p>
                  <p>Especialistas</p>
                </div>
              </v-col>
              <v-col cols="12" lg="3" sm="12" md="9">
                <div class="fontnormal">
                  <p class="font">COMUNIDAD</p>
                  <p>NIUFLEEX Bussiness</p>
                  <p>Aprende y Emprende</p>
                  <p>Ser Inquebrantable</p>
                </div>
              </v-col>
              <v-col cols="12" lg="3" sm="12" md="9">
                <div class="fontnormal">
                  <p class="font">AYUDA</p>
                  <p>Términos y Condiciones</p>
                  <p>Políticas de Privacidad</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="flex" flat color="#541983" tile >
          <v-row class="pa-10 ml-10">
            <v-col cols="12" lg="2" sm="12" md="9">
              <img class="mt-2" width="150" :src="images.sample" />
            </v-col>
            <v-col cols="12" lg="2" sm="12" md="9">
              <img class="mt-2 ml-1" width="150" :src="images.sample2" />
            </v-col>
            <v-col cols="12" lg="4" sm="12" md="9">
              <p
                style="
                  font-family: 'Raleway', sans-serif;
                  color: #b26fe7;
                  font-size: 13px;
                  margin-top: 10px;
                "
              >
                Copyright © NIUFLEEX 2022 – Todos los derechos reservados
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="3" sm="12" md="9">
              <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
                <v-icon style="color: #818a91" size="25px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-footer>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      icons: ["mdi-facebook", "mdi-instagram", "mdi-youtube"],
      images: {
        sample: "https://dsm01pap008files.storage.live.com/y4mdvJQTIXA925z69aDnC24VRP4LQaP2UUytvSl7pMk8tDaZfIrO82Y9h5PFta_xx00Vi5RxQN_NTWct7Mu1ZsMVxAXd_iw6dQWn-IoehdbBeJd6UueRg5TLrefeotJJ294YcYctTy_gYJpbo36c7ObRuvm2bQGQFCtp5GRmmEhD5jVovN8lVReVJ4baqqrrMJh?width=660&height=166&cropmode=none",
        sample2: require("../../assets/TEOMA.png"),
      },
    };
  },
};
</script>
<style scoped>
.font {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 17px;
  text-align: left;
  font-weight: bold;
}
.fontnormal {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 17px;
  text-align: left;
}
</style>
