<template>
  <v-card class="mt-3" color="purple darken-4">
    <v-row>
      <v-col cols="12" lg="4" sm="12" md="9"> </v-col>
      <v-col cols="12" lg="1" sm="12" md="9">
        <h2
          class="mt-12"
          style="
            border-radius: 5px;
            font-family: sans-serif;
            color: white;
            font-size: 30px;
            text-align: center;
            background-color: rgba(255, 0, 0, 0.8);
          "
        >
          Live
        </h2>
      </v-col>
      <v-col cols="12" lg="6" sm="12" md="9">
        <p
          style="
            font-family: 'Poppins', sans-serif;
            color: #ddb2ff;
            font-size: 37px;
            line-height: 55px;
          "
        >
          Transmisión en curso
        </p>
        <p
          style="
            font-family: 'Raleway', sans-serif;
            color: white;
            line-height: 1px;
            font-size: 17px;
          "
        >
          {{ banners.datosBannerFreeLive.title }}
        </p>
        <p
          style="
            font-family: 'Raleway', sans-serif;
            color: white;
            font-size: 17px;
            line-height: 15px;
          "
        >
          {{ banners.datosBannerFreeLive.subtitle }}
        </p>
      </v-col>
    </v-row>
    <div>
      <LazyYoutubeVideo
        src="https://www.youtube.com/embed/4JS70KB9GS0"
        aspect-ratio="16:9"
      />
    </div>
  </v-card>
</template>
<script>
import bannersData from "../../services/datosvercursos.json";
import LazyYoutubeVideo from "vue-lazy-youtube-video";
export default {
  banners: bannersData,

  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      banners: bannersData,
    };
  },
};
</script>
<style scoped></style>
