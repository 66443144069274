<template>
	<v-row>
		<v-col>
			<template v-if="!premium"
				><ToolbarPromo
					v-if="
						$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'
					"
				/>
				<ToolbarPromoMobile
					v-if="
						$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
					"
				/>
			</template>
			<v-row>
				<v-col cols="12" md="9">
					<div style="padding: 56.25% 0 0 0; position: relative">
						<iframe
							src="https://vimeo.com/event/2191789/embed"
							frameborder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							allowfullscreen
							style="
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
							"
						></iframe>
					</div>
				</v-col>
				<v-col cols="12" md="3">
					<iframe
						src="https://vimeo.com/event/2191789/chat/"
						width="100%"
						height="100%"
						frameborder="0"
					></iframe>
				</v-col>
			</v-row>
			<!-- <BannerFree /> -->
			<!-- <Trasmisiones /> -->
			<BannerFreeLive />
			<Programas />
			<!-- <Soporte />
      <Footer /> -->
			<!-- <Calificar /> -->
		</v-col>
	</v-row>
</template>
<script>
import ToolbarPromo from "../components/new_live/ToolbarPromo.vue";
import ToolbarPromoMobile from "../components/new_live/ToolbarPromoMobile.vue";
import Trasmisiones from "../components/new_live/Trasmisiones.vue";
import Soporte from "../components/new_live/Soporte.vue";
import Footer from "../components/new_live/Footer.vue";
import BannerFreeLive from "../components/new_live/BannerFreeLive.vue";
import BannerFree from "../components/new_live/BannerFree.vue";
import Programas from "../components/new_live/Programas.vue";
// import Calificar from "../components/ver_cursos/calificar.vue";
import { mapState } from "vuex";
export default {
	components: {
		ToolbarPromo,
		ToolbarPromoMobile,
		Trasmisiones,
		Footer,
		Programas,
		Soporte,
		BannerFreeLive,
		BannerFree,
	},
	data() {
		return {};
	},
	computed: {
		...mapState(["premium"]),
	},
};
</script>
<style scoped></style>
